import React, { useState } from "react";
import swal from "sweetalert";
import { Scanner } from '@yudiel/react-qr-scanner';
//import { useNavigate } from "react-router-dom";

const QrScanner = (props) => {
  //const navigate = useNavigate("");
  const [qrScanner, setQrScanner] = useState('No Result');

  if (qrScanner !== 'No Result') {
    //props.setQrScanValue(qrScanner);
    //console.log(qrScanner, '$$$rt');
    let Sanners = "Scan Successfully (" + qrScanner + ")";
    swal("Success", Sanners, "success");
    //navigate('/admin/dashboard'); //redirect to the home page
  }
  return (
    <>
      <div className="container">
        <div className='row'>

          <div className='col-md-12 m-3'>
            <div>QR Code Scanner</div>
            <div className="col-4">
              <Scanner
                onResult={(text) => {
                  setQrScanner(text);

                }
                }
                onError={(error) => console.log(error?.message)}

              />
              This is the Result: {qrScanner}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrScanner;


