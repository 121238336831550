import React, { useState, useEffect } from 'react';
import swal from "sweetalert";

const CropRatio = (props) => {
  const [aspectRatios, setAspectRatios] = useState(0);
  const [returnUploaders, setReturnUploader] = useState('');

  const aspectRatioss = [
    { value: "", name: "Select the Image Type" },
    { value: 7 / 4, name: "Landscape" },
    { value: 6.5 / 7, name: "Passport" },
    { value: 7 / 7, name: "Product" },
  ];
  useEffect(() => {
    setReturnUploader(props.returnUploader);
  }, [props.returnUploader]);
  props.setReturnRatio(aspectRatios);

  async function cropPhotoImage(event) {
    event.preventDefault();
    if ((aspectRatios === 'Please Image Type') || (!returnUploaders)) {

      return swal("Caution", "Please select an image to upload, then select the Image Type", "warning");
    }

    props.setCropImage(returnUploaders);
    props.setCloseModal(true);
  }

  return (
    <>
      <div className='col-md-9 mb-3'></div>
      <div className='col-md-3 mb-3'>
        <div className='mb-3'>
          <select
            className="form-select"
            id="aspectRatios"
            name="aspectRatios"
            value={aspectRatios}
            onChange={(event) => {
              setAspectRatios(event.target.value);
            }}
          >
            {
              aspectRatioss.map((item) => {
                return (<option value={item.id} key={item.id}>{item.name}</option>)
              })
            }
          </select>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-warning me-3 col-12"
            onClick={cropPhotoImage}
          ><i className="fa fa-image me-1"> </i>
            Crop Photo Image
          </button>
        </div>
      </div>
    </>
  )

}

export default CropRatio
