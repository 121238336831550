import React, { useState, useEffect } from 'react'
import { MdOutlineSubtitlesOff } from "react-icons/md";
const EndDate = (props) => {

    const [endDate, setEndDate] = useState('');

    const [nameId, setNameId] = useState('');// The title name
    const [size, setSize] = useState(6); // The size of the field
    const [inputState, setInputState] = useState(); // The size of the field
    const [placeholder, setPlaceholder] = useState(''); // The size of the field
    const [titles, setTitles] = useState(''); // The title of the field
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setEndDate(props.moreDetail.endDate); // The value

        setNameId(props.nameId);
        setSize(props.size);
        setInputState(props.inputState);
        setPlaceholder(props.placeholder);
        setTitles(props.titles);
    }, [props.moreDetail.endDate, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
    /* End of Load all File with data */
    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(endDate);
    }, [endDate]);

    props.setEndDate(updateData);
    /* End of Send all Files data to parents */

    return (
        <>

            <div className={"col-lg-" + size}>
                <div className="form-group">
                    <label for="endDate" className="col-lg-12">
                        <MdOutlineSubtitlesOff style={{ fontSize: '20px' }} />    {titles}
                        {/*Start Date */}
                    </label>
                    <div className="col-lg-12">
                        <input type="date"
                        className="form-control"

                            name={nameId}
                            disabled={inputState}
                            id={nameId}
                            placeholder={placeholder}

                            onChange={(event) => {
                                setEndDate(event.target.value);

                            }} value={endDate} />
                    </div>
                </div>
            </div>

        </>
    )

}

export default EndDate
