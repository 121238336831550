import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useNavigate
import swal from "sweetalert";
import Modal from "./childComponents/popups/Modal";
import Loading from "../admin/childComponents/appComponents/Loading";
import ActiveStatus from "./childComponents/inputComponents/ActiveStatus";
import PageCatigory from "./childComponents/inputComponents/PageCatigory";
import PageLayout from "./childComponents/inputComponents/PageLayout";

import { MdContentPasteSearch } from "react-icons/md";
//Purchaseditems, Quotemessages, Payments, Page404
const buttonName = 'Search Post';// Submit Button Name
const image_01 = localStorage.getItem("bend");

const AllPost = () => {
  const [pageLayout, setPageLayout] = useState('');
  const [pageCatigory, setPageCatigory] = useState('');
  const [activeStatus, setActiveStatus] = useState('');

  const [editPost, setEditPost] = useState('');
  const [displayMessages, setDisplayMessages] = useState('');

  const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
  const [allRecords, setAllRecords] = useState([]);
  const [loading, setloading] = useState(true);


  /* All records */
  useEffect(() => {
    (async () => await tableLoad())();
  }, []);

  async function tableLoad() {
    let datas = '?';
    let messages = '';
    if ((pageLayout) && (pageLayout !== 'Please Select Page Layout')) {
      datas = datas + "pageLayout[eq]=" + pageLayout + '&';
      messages = 'LAYOUT: ' + pageLayout;
    }
    if ((pageCatigory) && (pageCatigory !== 'Please Select Page Category')) {
      datas = datas + "pageCatigory[eq]=" + pageCatigory + '&';
      messages = messages + ' | CATEGORY: ' + pageCatigory;
    }
    if ((activeStatus) && (activeStatus !== 'Please Select Status')) {
      datas = datas + "activeStatus[eq]=" + activeStatus;
      messages = messages + ' | STATUS: ' + activeStatus;
    }

    if (datas === '?') {
      datas = '';
      messages = 'ALL DATA';
    }

    setDisplayMessages(messages);
    //datas = "?pageLayout[eq]="+pageLayout+"&pageCatigory[eq]="+pageCatigory+"&activeStatus[eq]="+activeStatus;
    await axios.get("api/admin/v1/post_page" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords(res.data.allData);
        setBtnLoading(buttonName);
        setloading(false);
        console.log(res.data.bend);
      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of All records */

  /* Defualt Search Records */
  const [allRecords_02, setAllRecords_02] = useState([]); //store defualt search records

  useEffect(() => {
    (async () => await tableLoad_02())();
  }, []);

  async function tableLoad_02() {
    let datas = '?';
    if (pageLayout) {
      datas = datas + "pageLayout[eq]=" + pageLayout + '&';
    }
    if (pageCatigory) {
      datas = datas + "pageCatigory[eq]=" + pageCatigory + '&';
    }
    if (activeStatus) {
      datas = datas + "activeStatus[eq]=" + activeStatus;
    }

    if (datas === '?') {
      datas = '';
    }
    await axios.get("api/admin/v1/post_page" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords_02(res.data.allData);

      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of Defualt Search Records */



  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newRecords = allRecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allRecords.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  /* End of Pagination */

  /* Search */
  const handleFilter = (event) => {
    let text = event.target.value;
    tableLoad_02();
    const newData = allRecords_02.filter((row) =>
      row.pageTitle.toLowerCase().includes(text.toLowerCase())
    );
    if (event) {
      setAllRecords(newData);
      setAllRecords_02(allRecords_02);
    }
  };

  /* End of Search */
  /*Clean the Form*/
  const formCleanner = () => {
    tableLoad();
  };
  /* End Clean the Form*/


  async function updatePost(event, posts) {
    event.preventDefault();

    setEditPost(posts);
  }
  async function searchData(event) {

    event.preventDefault();
    setBtnLoading(<div>Processsing...</div>);
    tableLoad();
    tableLoad_02();
  }
  async function DeletePost(event, id) {
    event.preventDefault();

    await swal({
      title: "Are you sureee!!?",
      text: "Once deleted, you will not be able to recover this data againoo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {//used to delete the file

          axios.get("sanctum/csrf-cookie").then((response) => {
            axios.delete("api/admin/v1/post_page/" + id).then((res) => {
              if (res.data.status === 200) {

                swal("Deleted", res.data.message, "success");
                console.log(res.data.message);
                formCleanner();
              } else {
                swal("Oops!", res.data.message, "warning");
                console.log(res.data.validation_error);
              }
            });
          });
        } else {
          swal("Saved", "The data is safe!", "success");
        }
      });

  }

  function numberWithCommas(x) {
    if (!x) { x = 0; }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //console.log(activeStatus, '$$$4');
  if (loading) {
    // Loading, Processing Payment, Please Dont Interrupt
    return <Loading message01={'Loading...'} />;
  }
  return (
    <>
      {/* modal Type, Title of the Modal Box, triger the modal, convay all the data inputs to be edithed to child */}
      <Modal modalType={'ModalPost'} titles={'EDIT POST'} trigger={true} editpost={editPost} formCleanner={formCleanner} />

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><i className="fa fa-search"></i> SEARCH POST</div>
              <div className="card-body">
                <div className="row m-1">

                  <PageCatigory titles='Page Category' nameId='pageCatigory' placeholder='Please Select Page Category' size='3' inputState='' moreDetail={'editposts'} setPageCatigory={setPageCatigory} />

                  <PageLayout titles='Page Layout' nameId='pageLayout' placeholder='Please Select Page Layout' size='3' inputState='' moreDetail={'editposts'} setPageLayout={setPageLayout} />

                  <ActiveStatus titles='Status' nameId='activeStatus' placeholder='Please Select Status' size='3' inputState='' moreDetail={'editposts'} setActiveStatus={setActiveStatus} />

                  <div className='col-lg-3'>
                    <div className="row form-group mt-3">
                      <div className='col-lg-3'>
                        <Link className='btn btn-primary col-12 mt-4' onClick={softreload} to='/admin/allpost'>
                          <i className='fa fa-refresh'></i>
                        </Link>
                      </div>
                      <div className='col-lg-9'>
                        <button className='btn btn-primary col-12 mt-4' onClick={searchData}>
                          <div className=' d-flex justify-content-center'>
                            <i className="fa fa-power-off m-1 col-lg-3"></i>{btnLoading}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><MdContentPasteSearch style={{ fontSize: '20px' }} /> ALL POST <span className="float-end me-3">{displayMessages}</span></div>
              <div className="card-body">
                <div className='row m-1'>

                  <div>
                    <div style={{ display: "flex", float: "right" }}>
                      <input
                        type="text"
                        name="searchData"
                        placeholder="...Search Title"
                        style={{ padding: "5px 7px" }}
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", overflowX: "scroll" }}>
                    <table className="table table-striped" style={{ width: "150%" }}>
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "5%" }}>Select</th>
                          <th scope="col" style={{ width: "15%" }}>Action</th>
                          <th scope="col" style={{ width: "20%" }}>Title</th>
                          <th scope="col" className="col-3" style={{ width: "20%" }}>Publication</th>
                          <th scope="col" style={{ width: "10%" }}>Image</th>
                          <th scope="col" style={{ width: "10%" }}>Quantity</th>
                          <th scope="col" style={{ width: "10%" }}>Price</th>
                          <th scope="col" style={{ width: "10%" }}>Prod. Cat.</th>

                        </tr>
                      </thead>
                      <tbody>
                        {newRecords.map((allRecord, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={allRecord.id}
                                />
                              </th>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-warning me-3"
                                  data-toggle="modal" data-target="#exampleModal"
                                  onClick={(event) => updatePost(event, allRecord)}

                                ><i className="fa fa-edit me-1"> </i>
                                  Edit
                                </button>


                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={(event) => DeletePost(event, allRecord.id)}
                                ><i className="fa fa-trash me-1"> </i>
                                  Delete
                                </button>
                              </td>

                              <td>{allRecord.pageTitle}</td>
                              <td>
                                <div dangerouslySetInnerHTML={{ __html: allRecord.message.substring(0, 150) + "..." }} />
                              </td>
                              <td><img src={image_01 + allRecord.uploadFile} alt="" width={70} height={80} /></td>
                              <td>{allRecord.quantity}</td>
                              <td>₦{numberWithCommas(allRecord.price)}</td>
                              <td>{allRecord.productCatigory}</td>

                            </tr>
                          );
                        })}

                      </tbody>
                    </table>
                  </div>

                  <nav>
                    <ul className="pagination">
                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={prePage}>
                          Prev
                        </Link>
                      </li>

                      {number.map((n, i) => (
                        <li
                          className={`page-item ${currentPage === n ? "active" : ""}`}
                          key={i}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={nextPage}>
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  function softreload() {
    window.location.reload();
  }

  /* Pagination*/
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    if (currentPage !== firstIndex) {
      setCurrentPage(id);
    }
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* End of Pagination*/
}

export default AllPost
