import React, { useState, useEffect } from 'react'
import Reviews from "../inputComponents/Reviews";
import Specification from "../inputComponents/Specification";

import ProductCatigory from "../inputComponents/ProductCatigory";
import Title from "../inputComponents/Title";
import Price from "../inputComponents/Price";
import Message from "../inputComponents/Message";
import Quantity from "../inputComponents/Quantity";
import Position from "../inputComponents/Position";
import Extra01 from "../inputComponents/Extra01";
import Extra02 from "../inputComponents/Extra02";
import Extra03 from "../inputComponents/Extra03";
import Extra04 from "../inputComponents/Extra04";

const PostUpdate = (props) => {
    const [reviews, setReviews] = useState('');
    const [specification, setSpecification] = useState('');
    const [message, setMessage] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const [position, setPosition] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [productCatigory, setProductCatigory] = useState();
    const [extra01, setExtra01] = useState('');
    const [extra02, setExtra02] = useState('');
    const [extra03, setExtra03] = useState('');
    const [extra04, setExtra04] = useState('');


    const [pageLayout, setPageLayout] = useState('');

    //reviews: '', specification: '', message: '', pageTitle: '', position: '', quantity: '', price: '', productCatigory: '', extra01: '', extra02: '', extra03: '', extra04: '' 
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setReviews(props.editpost.reviews);
        setSpecification(props.editpost.specification);
        setMessage(props.editpost.message);
        setPageTitle(props.editpost.pageTitle);
        setPosition(props.editpost.position);
        setQuantity(props.editpost.quantity);
        setPrice(props.editpost.price);
        setProductCatigory(props.editpost.productCatigory);
        setExtra01(props.editpost.extra01);
        setExtra02(props.editpost.extra02);
        setExtra03(props.editpost.extra03);
        setExtra04(props.editpost.extra04);

        setPageLayout(props.pageLayout);
    }, [props.editpost, props.pageLayout]);
    /* End of Load all File with data */

    /* Send all Files data to parents */
    useEffect(() => {
        const allInfo = { position, pageTitle, price, quantity, productCatigory, reviews, specification, message, extra01, extra02, extra03, extra04 };

        setUpdateData(allInfo);

    }, [position, pageTitle, price, quantity, productCatigory, reviews, specification, message, extra01, extra02, extra03, extra04]);

    props.setData(updateData);
    /* End of Send all Files data to parents */

    let tagName;
    //About, ChooseUs, Quote, Contact, Product, Projects, Service, Team, Feature, Testimonial
    switch (pageLayout) {
        case "About":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

                <Extra01 titles='No. Clients' nameId='extra01' placeholder='No. of Happy Clients' size='6' inputState='' moreDetail={props.editpost} setExtra01={setExtra01} />

                <Extra02 titles='No. of Projects' nameId='extra02' placeholder='No. of Projects Done' size='6' inputState='' moreDetail={props.editpost} setExtra02={setExtra02} />

            </>;
            break;
        case "Disclaimer":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

            </>;
            break;
        case "Quote":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />
            </>;
            break;

        case "Team":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

                <Extra01 titles='Designation' nameId='extra01' placeholder='Office Designation' size='6' inputState='' moreDetail={props.editpost} setExtra01={setExtra01} />

                <Extra02 titles='Facebook' nameId='extra02' placeholder='Facebook Account' size='6' inputState='' moreDetail={props.editpost} setExtra02={setExtra02} />

                <Extra03 titles='Twitter' nameId='extra03' placeholder='Twitter Account' size='6' inputState='' moreDetail={props.editpost} setExtra03={setExtra03} />

                <Extra04 titles='Instagram' nameId='extra04' placeholder='Instagram Account' size='6' inputState='' moreDetail={props.editpost} setExtra04={setExtra04} />

            </>;
            break;
        case "Testimonial":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

                <Extra01 titles='Discipline' nameId='extra01' placeholder='Discipline' size='6' inputState='' moreDetail={props.editpost} setExtra01={setExtra01} />

            </>;
            break;
        case "ChooseUs":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

                <Extra01 titles='Quality' nameId='extra01' placeholder='Quality' size='6' inputState='' moreDetail={props.editpost} setExtra01={setExtra01} />

                <Extra02 titles='Creative' nameId='extra02' placeholder='Creative' size='6' inputState='' moreDetail={props.editpost} setExtra02={setExtra02} />

                <Extra03 titles='Free' nameId='extra03' placeholder='Free' size='6' inputState='' moreDetail={props.editpost} setExtra03={setExtra03} />

                <Extra04 titles='Customer' nameId='extra04' placeholder='Customer' size='6' inputState='' moreDetail={props.editpost} setExtra04={setExtra04} />

            </>;
            break;
        case "Contact":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

            </>;
            break;
        case "Product":
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <ProductCatigory titles='Product Catigory' nameId='productCatigory' placeholder='Product Category' size='6' inputState='' moreDetail={props.editpost} setProductCatigory={setProductCatigory} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Price titles='Price' nameId='price' placeholder='Price' size='6' inputState='' moreDetail={props.editpost} setPrice={setPrice} />

                <Quantity titles='Quantity' nameId='quantity' placeholder='Quantity' size='6' inputState='' moreDetail={props.editpost} setQuantity={setQuantity} />

                <Message titles='Product Description' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />

                <Specification titles='Product Specification' nameId='specification' placeholder='Product Specification' size='12' inputState='' moreDetail={props.editpost} setSpecification={setSpecification} />

                <Reviews titles='Product Reviews' nameId='reviews' placeholder='Product Reviews' size='12' inputState='' moreDetail={props.editpost} setReviews={setReviews} />

            </>;
            break;
        default:
            tagName = <>
                <Title titles='Your Names' nameId='title' placeholder='Your First and Second Names' size='6' inputState='' moreDetail={props.editpost} setPageTitle={setPageTitle} />

                <Position titles='Position' nameId='position' placeholder='Please Select Layout Position on Page' size='6' inputState='' moreDetail={props.editpost} setPosition={setPosition} />

                <Message titles='Message' nameId='message' placeholder='Publication/ Description' size='12' inputState='' moreDetail={props.editpost} setMessage={setMessage} />
            </>;

    }
    return (
        <>
            <div className='col-md-9 mb-3'>
                <div className="card">
                    <div className="card-header text-white bg-info p-3"><i className="fa fa-shopping-cart"></i> {props.pageLayout}</div>
                    <div className="card-body">
                        <div className='row m-1'>
                            {tagName}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

export default PostUpdate
