import React, { useState, useEffect } from 'react';
import axios from "axios";
import signatureLogo from "../../../../assets/admin/assets/img/Signature.jpg";
import CompanyLogo from "../../../../assets/admin/assets/img/logos/smallDynamoIcon.png";
import Pdf from "../appComponents/Pdf";
import swal from "sweetalert";

const ModalPurchItems = (props) => {

    let path = window.location.origin;

    const [shippingButt, setShippingButt] = useState(true);

    const [totalPaided, setTotalPaided] = useState('');
    //const [uploadFile, setUploadFile] = useState('');
    const [childTrackNo, setChildTrackNo] = useState('');
    const [dateDatestramp, setDateDatestramp] = useState('');
    const [state, setState] = useState('');
    const [townCity, setTownCity] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [countryRegion, setCountryRegion] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [lastName, setLastName] = useState('');
    const [referenceNo, setReferenceNo] = useState('');
    const [hostCompAdd, setHostCompAdd] = useState('');
    const [hostCompName, setHostCompName] = useState('');
    const [direction, setDirection] = useState('');

    const [cartItems, setCartItems] = useState([]);

    const [pdfPrint, setPdfPrint] = useState(false);
    const [itemId, setItemId] = useState(false);

    useEffect(() => {
        (async () => await generatPrint())();
        setItemId(props.itemId);
        setPdfPrint(props.pdfPrint);
        setDirection(props.direction);

        if (props.direction === '1') {
            setShippingButt(true);

        } else if (props.direction === '2') {
            setShippingButt(false);

        }

    }, [props.itemId, props.pdfPrint, props.direction]);


    const generatPrint = async () => {
        if (props.itemId) {
            let datas;
            datas = "?referenceNo[eq]=" + props.itemId;
            await axios.get("api/admin/v1/cart_items" + datas).then((res) => {
                // Gets the address of the backend server to be able to point the Images currectly
                if (res.data.status === 200) {
                    //localStorage.setItem("bend", res.data.bend);
                    //let alldatad = res.data.allData;
                    console.log(res.data.allData.itemName, '$$$TTT');
                    setCartItems(res.data.allData02);

                    res.data.allData.map((cartitemes, index) => {
                        setTotalPaided(cartitemes.totalPaided);
                        setChildTrackNo(cartitemes.childTrackNo);
                        setDateDatestramp(cartitemes.created_at);
                        setState(cartitemes.state);
                        setTownCity(cartitemes.townCity);
                        setStreetAddress(cartitemes.streetAddress);
                        setCountryRegion(cartitemes.countryRegion);
                        setPhoneNumber(cartitemes.phoneNumber);
                        setMessage(cartitemes.message);
                        setFirstName(cartitemes.firstName);
                        setEmail(cartitemes.email);
                        setLastName(cartitemes.lastName);
                        setReferenceNo(cartitemes.referenceNo);
                        setHostCompName(cartitemes.hostCompName);
                        setHostCompAdd(cartitemes.hostCompAdd);
                        return <></>
                    });


                } else if (res.data.status === 500) {
                    swal("Oop!", res.data.message, "warning");
                    setShippingButt(false);
                    console.log(res.data.message, "Oop!");
                }
            });
        }
    }

    function numberWithCommas(x) {
        if (!x) { x = 0; }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let d = new Date(dateDatestramp);
    let month = months[d.getMonth()];
    let day = d.getDate();
    let year = d.getFullYear();
    let completDate = day + ' ' + month + ', ' + year;

    const printDoc = async (event) => {
        event.preventDefault();

        setShippingButt(false);
        setPdfPrint(true);
        props.formCleanners();
        const datas = {
            id: itemId,
            mail: 'no',
        };

        await axios.post("api/admin/v1/shipment_mail", datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                //res.data.allData
                swal("Messages Sent", res.data.message, "success");

            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }
    return (
        <>
            {pdfPrint ? <Pdf docType={'ModalPurchItems'} id={itemId} referenceNo={path + '/verifyreceipt?ids=' + itemId} /> :


                <div className='row m-1'>
                    <table width="100%" border="0" cellspacing="0" cellpadding="5">
                        <tr>
                            <td style={{ fontWeight: "bold", fontSize: '45px', width: '25%', textAlign: 'left' }}>RECEIPT</td>
                            <td style={{ width: '25%', textAlign: 'left' }}>&nbsp;</td>
                            <td style={{ width: '25%', textAlign: 'left' }}>&nbsp;</td>
                            <td style={{ width: '25%', textAlign: 'right' }}><img name="" src={CompanyLogo} width="100" height="100" alt="" /></td>
                        </tr>
                        <tr>
                            <td colspan="4"><h4>{hostCompName}</h4></td>
                        </tr>
                        <tr>
                            <td>{hostCompAdd}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td colspan="4">&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="4"><table width="100%" border="0" cellspacing="0" cellpadding="5">
                                <tr>
                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'left' }}>BILL TO</td>
                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'left' }}>SHIP TO</td>
                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'right' }}>INVOICE #</td>
                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'right' }}>{referenceNo}</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">
                                        <p>{lastName} {firstName},
                                            <br /><h5 className='pb-0 mb-0'>Phone: {phoneNumber}</h5>
                                            <br />{email},
                                            <br />{streetAddress},
                                            <br /> {townCity} {state},
                                            <br /> {countryRegion}
                                        </p>
                                    </td>
                                    <td rowspan="3">
                                        <p>{lastName} {firstName},
                                            <br /><h5 className='pb-0 mb-0'>Phone: {phoneNumber}</h5>
                                            <br />{email},
                                            <br /> {streetAddress},
                                            <br /> {townCity} {state},
                                            <br /> {countryRegion}
                                        </p>
                                    </td>
                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }} className="fw-bold">
                                        INVOICE DATE<br />
                                        ITEM NUMBER
                                    </td>
                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }}>{completDate}<br />{childTrackNo}</td>
                                </tr>
                                <tr>
                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }}></td>
                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }}></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </table></td>
                        </tr>
                        <tr>
                            <td colspan="4"><table width="100%" border="0" cellspacing="0" cellpadding="5">
                                <tr>
                                    <td valign="top" className="fw-bold">Message:</td>
                                    <td>{message}</td>
                                </tr>
                            </table></td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <table width="100%" border="0" cellspacing="0" cellpadding="5">
                                    <tr>
                                        <td bgcolor="#CCCCCC" className="fw-bold">QTY</td>
                                        <td bgcolor="#CCCCCC" className="fw-bold">DESCRIPTION</td>
                                        <td bgcolor="#CCCCCC" className="fw-bold">UNIT PRICE</td>
                                        <td bgcolor="#CCCCCC" className="fw-bold">AMOUNT</td>
                                    </tr>
                                    {

                                        cartItems.map((cartitem, index) => {

                                            return (
                                                <tr>
                                                    <td>{cartitem.quantity}</td>
                                                    <td>{cartitem.itemName}</td>
                                                    <td>N{numberWithCommas(cartitem.unitCost)}</td>
                                                    <td>N{numberWithCommas(cartitem.totalCost)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td className="fw-bold">TOTAL</td>
                                        <td bgcolor="#CCCCCC" className="fw-bold">N{numberWithCommas(totalPaided)}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td><img name="" src={signatureLogo} width="100" height="100" alt="" /></td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                        <tr>
                            <td><h5>Terms &amp; Conditions</h5></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>


                    {shippingButt &&
                        <div className='col-lg-3'>
                            <div className="form-group mt-3">
                                <button className='btn btn-primary col-9 ms-3 mt-4' onClick={printDoc}><div className=' d-flex flex-row'> <i className="fa fa-print m-1 col-3"></i>Print Receipt</div></button>
                            </div>
                        </div>}




                </div >


            }
        </>)

}
export default ModalPurchItems