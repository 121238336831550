import React, { useState, useEffect } from 'react';
import axios from "axios";
import { IoMdMailUnread } from "react-icons/io";
import swal from "sweetalert";
import Email from "../../childComponents/RequestQuoteContact/Email";
import Subject from "../../childComponents/RequestQuoteContact/Subject";
import TextMessage from "../../childComponents/RequestQuoteContact/TextMessage";
import { MdOutlineSettingsBackupRestore, MdOutlineMarkEmailRead } from "react-icons/md";
import { LuMessagesSquare } from "react-icons/lu";
import { MdSubject } from "react-icons/md";


const buttonName = 'Send Mail';// Submit Button Name
const ModalMailsQuots = (props) => {

    const [replyMail, setReplyMail] = useState(true);
    const [trackId, setTrackId] = useState('');

    const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [textMessage, setTextMessage] = useState('');
    const [message, setMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [messageSubject, setMessageSubject] = useState('');


    const [names, setNames] = useState('');
    //const [uploadFile, setUploadFile] = useState('');
    //const [status, setStatus] = useState('');
    const [dateDatestramp, setDateDatestramp] = useState('');
    const [typeRequest, setTypeRequest] = useState('');
    const [mobile, setMobile] = useState('');

    //const [createdat, setCreatedat] = useState('');

    const [productCat, setProductCat] = useState('');
    const [contactSubject, setContactSubject] = useState(false);
    const [quoteProduct, setQuoteProduct] = useState(false);

    //const [direction, setDirection] = useState('');
    const [readMail, setReadMail] = useState(true);
    const [itemId, setItemId] = useState();
    const [moreDetail, setMoreDetail] = useState();

    useEffect(() => {

        setItemId(props.itemId);
        setReadMail(props.readMail);
        //setDirection(props.direction);
        console.log(props.itemId, '$$$TTT');
        if (props.direction === '1') {
            setReplyMail(true);
        } else if (props.direction === '2') {
            setReplyMail(false);
        }
        (async () => generatPrint())();

    }, [props.readMail, props.direction, props.itemId]);

    useEffect(() => {
        
        setMoreDetail({ email: senderEmail, names: names, mobile: mobile, textMessage: message, subject: messageSubject, productCat: productCat });
    }, [senderEmail, names, mobile, message, messageSubject, productCat]);

    const generatPrint = () => {
        if (props.itemId) {
            let datas;
            datas = "?id[eq]=" + props.itemId;

            axios.get("api/admin/v1/feeds_page_admin" + datas).then((res) => {
                // Gets the address of the backend server to be able to point the Images currectly
                if (res.data.status === 200) {
                    //localStorage.setItem("bend", res.data.bend);
                    //let alldatad = res.data.allData;

                    setTrackId(res.data.allData.trackId);
                    setSenderEmail(res.data.allData.email);
                    setNames(res.data.allData.names);

                    //setStatus(res.data.allData.status);
                    setDateDatestramp(res.data.allData.created_at);
                    //props.formCleanners();
                    setTypeRequest(() => {
                        let chose;
                        if (res.data.allData.typeRequest === 'Contact') {
                            chose = 'Contact Us';
                            setContactSubject(true);
                            setQuoteProduct(false);
                        } else {
                            chose = 'Request Quote';
                            setQuoteProduct(true);
                            setContactSubject(false);
                        }
                        return chose;
                    });
                    setMobile(res.data.allData.mobile);
                    setMessage(res.data.allData.textMessage);

                    //setCreatedat(res.data.allData.created_at);
                    setMessageSubject(() => {
                        let chose;
                        if (res.data.allData.subject) {
                            setMessageSubject(res.data.allData.subject);
                        } else {
                            setMessageSubject(res.data.allData.productCat);
                        }
                        return chose;
                    });
                    res.data.allData.productCat ? setProductCat(res.data.allData.productCat) : setProductCat(res.data.allData.subject);


                } else if (res.data.status === 500) {
                    swal("Oop!", res.data.message, "warning");
                    setReplyMail(false);
                    console.log(res.data.message, "Oop!");
                }
            });
        }
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let d = new Date(dateDatestramp);
    let month = months[d.getMonth()];
    let day = d.getDate();
    let year = d.getFullYear();
    let completDate = day + ' ' + month + ', ' + year;

    const replyMailDoc = async (event) => {
        event.preventDefault();
        setReplyMail(true);
        setReadMail(false);
    }

    const sendMails = async (event) => {
        event.preventDefault();
        setBtnLoading(<div>Processsing...</div>);
        const datas = {
            email: email,
            subject: subject,
            textMessage: textMessage,
            trackId: trackId,
            status: 'response',
            names: 'Response Mail',
            mobile: mobile,
        };

        await axios.post("api/admin/v1/responce_mail", datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                //res.data.allData
                swal("Messages Sent", res.data.message, "success");
                props.setCloseModal(true);
                //props.formCleanners();
                setBtnLoading(buttonName);
            } else if (res.data.status === 401) {
                swal("Oop!", res.data.message, "warning");

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");

            }
            else {
                swal("Warning", res.data.message, "warning");
                console.log(res.data.message);
                console.log(res.data.validation_error);

            }
        });

    }
    console.log(moreDetail, '$$44');

    return (
        <>
            {readMail ?
                <div className='row m-1'>
                    
                        <div className='row justify-content-center'>
                            {replyMail &&

                                <div className='col-lg-9'>
                                    <div className="form-group">
                                        <button className='btn btn-primary col-12 my-4' onClick={replyMailDoc}>
                                            <div className=' d-flex-center flex-row col-12'>
                                                <IoMdMailUnread style={{ fontSize: '20px' }} /> <h6>Reply Mail</h6>
                                            </div>
                                        </button>
                                    </div>
                                </div>}

                            <div className='row col-9'>
                                <div className='col-6 border-bottom py-3'>
                                    <h6>Message From:</h6>
                                    <h5>{names}</h5>
                                </div>
                                <div className='col-6 border-bottom py-3'>
                                    <h6>From Email:</h6>
                                    <h5>{senderEmail}</h5>
                                </div>
                                <div className='col-6 border-bottom py-3'>
                                    <h6>Phone Number:</h6>
                                    <h5>{mobile}</h5>
                                </div>
                                {contactSubject && <div className='col-6 border-bottom py-3'>
                                    <h6>Subject:</h6>
                                    <h5>{messageSubject}</h5>
                                </div>}
                                {quoteProduct && <div className='col-6 border-bottom py-3'>
                                    <h6>Product Category:</h6>
                                    <h5>{productCat}</h5>
                                </div>}
                                <div className='col-6 border-bottom py-3'>
                                    <h6>Date:</h6>
                                    <h5>{completDate}</h5>
                                </div>
                                <div className='col-6 border-bottom py-3'>
                                    <h6>Mail Type:</h6>
                                    <h5 className='text-primary'>{typeRequest}</h5>
                                </div>
                                <div className='col-12 py-3'>
                                    <h6>Message:</h6>
                                    <h5><div dangerouslySetInnerHTML={{ __html: message }} /></h5>
                                </div>
                            </div>


                        </div>
                    </div>
                 :


                <div className='row m-1'>
                    
                        <div className='row justify-content-center'>

                            {replyMail &&
                                <div className='col-lg-9'>
                                    <div className="form-group">
                                        <button className='btn btn-primary col-12 my-4' onClick={sendMails}>
                                            <div className=' d-flex-center flex-row col-12'>
                                                <IoMdMailUnread style={{ fontSize: '20px' }} /> <h6>{btnLoading}</h6>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            }


                            <div className='row col-9'>
                                <div className='col-6 py-3'>
                                    <h6><MdOutlineMarkEmailRead style={{ fontSize: "20px" }} /> To:</h6>
                                    <Email titles='Email' nameId='email' placeholder='Recipient  Email' size='12' inputState='' moreDetail={moreDetail} setEmail={setEmail} />
                                </div>
                                <div className='col-6 py-3 text-end'>
                                    <button className='btn btn-success col-6 my-2'
                                        onClick={() => {
                                            setReplyMail(true);
                                            setReadMail(true);

                                        }}>
                                        <div className=' d-flex-center flex-row col-12'>
                                            <MdOutlineSettingsBackupRestore style={{ fontSize: '20px' }} /> <h6>Back To Message</h6>
                                        </div>
                                    </button>
                                </div>

                                <div className='col-12 py-3'>
                                    <h6><MdSubject style={{ fontSize: "20px" }} /> Subject:</h6>
                                    <Subject titles='Subject' nameId='subject' placeholder='Subject' size='12' inputState='' moreDetail={'props.editpost'} setSubject={setSubject} />
                                </div>

                                <div className='col-12 py-3'>
                                    <h6><LuMessagesSquare style={{ fontSize: "20px" }} /> Message:</h6>
                                    <TextMessage titles='Text Message' nameId='textMessage' placeholder='Overview of what you want' size='12' inputState='' moreDetail={'props.editpost'} setTextMessage={setTextMessage} />
                                </div>
                            </div>

                        </div>
                    
                </div >


            }
        </>)

}
export default ModalMailsQuots