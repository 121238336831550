import React, { useState, useEffect } from 'react';
import axios from "axios";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import Crop from "../../components/admin/childComponents/appComponents/Crop";

const Sidebar = () => {
  // /* For Image Crop */
  const [returnCrop, setReturnCrop] = useState(null);
  const [cropImage, setCropImage] = useState('');
  const [closeModal, setCloseModal] = useState(false);

  const [staffImage, setStaffImage] = useState('');
  const [admin_type, setAdmin_type] = useState('');
  const [adminId, setAdminId] = useState('');


  useEffect(() => {
    setAdmin_type(localStorage.getItem("admin_type"));
    setStaffImage(localStorage.getItem('admin_passport'));
    setAdminId(localStorage.getItem('admin_id'));
  }, []);

  let image_02 = localStorage.getItem("bend") + staffImage;


  const loadFile = function (event) {
    let myFileUrl = URL.createObjectURL(event.target.files[0]);
    setCropImage(myFileUrl);
    setCloseModal(true);
  }
  useEffect(() => {
    (returnCrop) && (submitUpload());
  }, [returnCrop]);

  async function submitUpload() {

    //event.preventDefault();
    let fileUploadItem
    if (!returnCrop) {
      console.log("No file Selected");
      return swal("Caution", "Please select a file or image to upload", "warning");
    } else {
      fileUploadItem = returnCrop;
      console.log('$$$Next', returnCrop);
    }

    const formData = new FormData();
    formData.append('id', adminId);
    formData.append('uploadFile', fileUploadItem);
    formData.append('myFileUpdate', staffImage);
    formData.append('imagePath', 'upload/admin_passports/');
    formData.append('dbName', 'admindatas');
    formData.append('dbColume', 'passport');

    await axios.get(`sanctum/csrf-cookie`).then((response) => {
      try {
        axios.post(`api/admin/v1/cameras/item`, formData).then((res) => {
          if (res.data.status === 200) {

            /*
            localStorage.setItem('auth_token', res.data.token);
            localStorage.setItem('auth_name', res.data.firstName);
            */
            localStorage.setItem('admin_passport', res.data.imagePath);
            setStaffImage(res.data.imagePath);
            swal("Success", res.data.message, "success");

            //navigate('/admin/allpost'); //redirect to the 
          } else if (res.data.status === 500) {

            swal("Caution", res.data.message, "warning");
            //navigate("/register02"); //redirect to the home page
          } else {
            swal("Failed", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
          }

        });
      } catch (error) {
        swal("Oops!", "Profile Image Update Failed", "error");
        console.log(error.response.data);
      }
    });
  }

  return (
    <>
      <div>
        {
          closeModal ? (
            <Crop imageCropUrl={cropImage} setReturnCrop={setReturnCrop} aspectRatios={1} setCloseModal={setCloseModal} />) : null
        }
      </div>
      {/* START SIDEBAR*/}
      <nav className="page-sidebar" id="sidebar">
        <div id="sidebar-collapse">
          <div className="admin-block">
            <div className="avatar-upload text-light d-flex my-4 text-light">
              <div className="col-md-4 m-0 p-0">
                <div className="avatar-edit admin-info">
                  <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={loadFile} />
                  <label for="imageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <img src={image_02} alt="" id="imagePreview" width="80px" className='rounded-circle' />

                </div>
              </div>
              <div class="admin-info">
                <div className="font-strong">{localStorage.getItem("auth_name").substring(0, 12) + "..."}</div><small>{admin_type}</small>
              </div>
            </div>
          </div>
          <ul className="side-menu metismenu">
            <li>
              <Link className="active" to="/admin/dashboard"><i className="sidebar-item-icon fa fa-th-large" />
                <span className="nav-label">Dashboard</span>
              </Link>
            </li>
            <li className="heading bg-gradient fw-normal text-light" style={{ backgroundColor: '#415E78' }}>PUBLISHING</li>
            <li>
              <Link to="#">
                <i className="sidebar-item-icon fa fa-file-text" id="navbarDropdown" data-bs-toggle="dropdown" />
                <span className="nav-label">Post
                  <div className="badge bg-warning rounded-1 float-end fw-normal text-light" style={{ float: "right", marginRight: "7%" }}>2</div>
                </span>
                <i className="fa fa-angle-left arrow" />
              </Link>
              <ul className="nav-2-level" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/admin/post">New Post</Link>
                </li>
                <li>
                  <Link to="/admin/allpost">All Post</Link>
                </li>
              </ul>
            </li>

            <li className="heading bg-gradient fw-normal text-light" style={{ backgroundColor: '#415E78' }}>MANAGEMENT</li>

            <li>
              <Link to="#">
                <i className="sidebar-item-icon fa fa-bookmark" id="navbarDropdown" data-bs-toggle="dropdown" />
                <span className="nav-label">Products
                  <div className="badge bg-primary rounded-1 float-end fw-normal text-light" style={{ float: "right", marginRight: "7%" }}>2</div>
                </span>
                <i className="fa fa-angle-left arrow" />
              </Link>
              <ul className="nav-2-level" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/admin/purchaseditems">Purchased Items</Link>
                </li>
                <li>
                  <Link to="/admin/quotemessages">Quote / Messages</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#">
                <i className="sidebar-item-icon fa fa-bar-chart" id="navbarDropdown" data-bs-toggle="dropdown" />
                <span className="nav-label">Finance
                  <div className="badge bg-success rounded-1 float-end fw-normal text-light" style={{ float: "right", marginRight: "7%" }}>1</div>
                </span>
                <i className="fa fa-angle-left arrow" />
              </Link>
              <ul className="nav-2-level" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/admin/payments">Acct. Statement</Link>
                </li>
              </ul>
            </li>


          </ul>
        </div>
      </nav>
      {/* END SIDEBAR*/}

    </>
  )
}
export default Sidebar;