import React, { useState, useEffect } from 'react'
import { GiPositionMarker } from "react-icons/gi";
const Position = (props) => {


    const [position, setPosition] = useState('');

    const [nameId, setNameId] = useState('');// The title name
    const [size, setSize] = useState(6); // The size of the field
    const [inputState, setInputState] = useState(); // The size of the field
    const [placeholder, setPlaceholder] = useState(''); // The size of the field
    const [titles, setTitles] = useState(''); // The title of the field
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setPosition(props.moreDetail.position); // The value

        setNameId(props.nameId);
        setSize(props.size);
        setInputState(props.inputState);
        setPlaceholder(props.placeholder);
        setTitles(props.titles);
    }, [props.moreDetail.position, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
    /* End of Load all File with data */

    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(position);
    }, [position]);

    props.setPosition(updateData);
    /* End of Send all Files data to parents */



    /* Loads the select fileds */

    const positions = [
        { value: "", name: "Please Select Layout Position on Page" },
        { value: "0", name: "0" },
        { value: "1", name: "1" },
        { value: "2", name: "2" },
        { value: "3", name: "3" },
        { value: "4", name: "4" },
        { value: "5", name: "5" },
        { value: "6", name: "6" },
        { value: "7", name: "7" },
        { value: "8", name: "8" },
        { value: "9", name: "9" },
        { value: "10", name: "10" },
        { value: "11", name: "11" },
        { value: "12", name: "12" },
        { value: "13", name: "13" },
        { value: "14", name: "14" },
        { value: "15", name: "15" },
        { value: "16", name: "16" },
        { value: "17", name: "17" },
        { value: "18", name: "18" },
        { value: "19", name: "19" },
        { value: "20", name: "20" },
    ];
    /* End of Loads the select fileds */

    return (
        <>

            <div className={'col-lg-' + size}>
                <div className="form-group mt-3">
                    <label for={nameId} className="col-sm-12">
                        <GiPositionMarker style={{ fontSize: '20px' }} />    {titles}
                        {/* Position */}
                    </label>
                    <div className="col-sm-12">
                        <select
                            className="form-select"

                            name={nameId}
                            disabled={inputState}
                            id={nameId}
                            placeholder={placeholder}

                            value={position}
                            onChange={(event) => {
                                setPosition(event.target.value);

                            }}
                        >
                            {
                                positions.map((item) => {
                                    return (<option value={item.id} key={item.id}>{item.name}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>



        </>
    )

}

export default Position
