import React, { useState, useEffect } from 'react'
import axios from "axios";
import { MdOutlineContactPage } from "react-icons/md";
const PageCatigory = (props) => {
  const [allRecords, setAllRecords] = useState([]); //store defualt search records
  const [pageCatigory, setPageCatigory] = useState('');

  const [nameId, setNameId] = useState('');// The title name
  const [size, setSize] = useState(6); // The size of the field
  const [inputState, setInputState] = useState(); // The size of the field
  const [placeholder, setPlaceholder] = useState(''); // The size of the field
  const [titles, setTitles] = useState(''); // The title of the field
  const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
  /* Load all File with data */
  useEffect(() => {
    setPageCatigory(props.moreDetail.pageCatigory); // The value

    setNameId(props.nameId);
    setSize(props.size);
    setInputState(props.inputState);
    setPlaceholder(props.placeholder);
    setTitles(props.titles);
  }, [props.moreDetail.pageCatigory, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
  /* End of Load all File with data */

  /* Send all Files data to parents */
  useEffect(() => {
    setUpdateData(pageCatigory);
  }, [pageCatigory]);

  props.setPageCatigory(updateData);
  /* End of Send all Files data to parents */

  /* Loads the select fileds */
  useEffect(() => {
    (async () => await tableLoad())();
  }, []);

  async function tableLoad() {
    let datas = '';

    datas = "?type[eq]=pageCatigory&activeStatus[eq]=Active";

    await axios.get("api/user/v1/catigory" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords(res.data.allData);

      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of Loads the select fileds */
  return (
    <>
      <div className={'col-lg-' + size}>
        <div className="form-group mt-3">
          <label for="pageCatigory" className="col-sm-12">
            <MdOutlineContactPage style={{ fontSize: '20px' }} />  {titles}
            {/* Page Category */}
          </label>
          <div className="col-sm-12">
            <select
              className="form-select"

              name={nameId}
              disabled={inputState}
              id={nameId}
              placeholder={placeholder}

              value={pageCatigory}
              onChange={(event) => {
                setPageCatigory(event.target.value);
              }}
            >
              <option value=''>{placeholder}</option> {/*Please Select Page Category */}
              {
                allRecords.map((item, index) => {
                  return (<option value={item.productCategory} key={item.id}>{item.productCategory}</option>)
                })
              }
            </select>
          </div>
        </div>
      </div>
    </>
  )

}

export default PageCatigory
