//import QRCode from "react-qr-code";
import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
const QrCodes = (props) => {
	const [qrcodeValue, setQrcodeValue] = useState('');
	const [qrcodeSize, setQrcodeSize] = useState('');

	useEffect(() => {
		setQrcodeValue(props.addrurl);
		setQrcodeSize(props.qrcodeSize);
	}, [props.addrurl, props.qrcodeSize]);

	return (
		<>
			<div className='col-md-10 m-5'>
				<QRCodeCanvas
					value={qrcodeValue}
					size={256}
					style={{ height: "auto", maxWidth: "100%", width: qrcodeSize }}
					level='Q'
				/*
					value= "https://savvypremier.ng"
					bgColor= 'Blue'
					fgColor= 'yellow'
					level= 'L'
					level=' ('L' 'M' 'Q' 'H')'
				*/
				/>
			</div>
		</>
	);
};

export default QrCodes;