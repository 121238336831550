import React, { useEffect, useState } from 'react'
const Email = (props) => {

    const [email, setEmail] = useState('');

    const [nameId, setNameId] = useState('');// The title name
    const [size, setSize] = useState(6); // The size of the field
    const [inputState, setInputState] = useState(); // The size of the field
    const [placeholder, setPlaceholder] = useState(''); // The size of the field
    const [titles, setTitles] = useState(''); // The title of the field
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setEmail(props.moreDetail.email); // The value

        setNameId(props.nameId);
        setSize(props.size);
        setInputState(props.inputState);
        setPlaceholder(props.placeholder);
        setTitles(props.titles);
    }, [props.moreDetail.email, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
    /* End of Load all File with data */
    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(email);
    }, [email]);

    props.setEmail(updateData);
    /* End of Send all Files data to parents */
    return (
        <>
            <div className={"col-md-" + size}>
                <div className="form-floating">
                    <input type="email" className="form-control"

                        name={nameId}
                        disabled={inputState}
                        id={nameId}
                        placeholder={placeholder}

                        value={email}
                        style={{ height: "55px" }} onChange={(event) => {
                            setEmail(event.target.value);
                        }} />
                    <label for="subject">{titles}</label>
                </div>
            </div>
        </>
    )
}

export default Email
