import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"; //useNavigate
import swal from "sweetalert";
import Modal from "./childComponents/popups/Modal";
import Loading from "../admin/childComponents/appComponents/Loading";

import { MdContentPasteSearch } from "react-icons/md";
import { PiMailboxFill } from "react-icons/pi";
import { FaMailBulk, FaRegTrashAlt } from "react-icons/fa";
import { MdRequestPage } from "react-icons/md";
import { RiMailSendFill } from "react-icons/ri";

//Purchaseditems, Quotemessages, Payments, Page404
const buttonName = 'Search Post';// Submit Button Name

const Quotemessages = () => {
  const { trackId } = useParams();
  const [trackIds, setTrackIds] = useState({ trackId }.trackId);
  const [editPost, setEditPost] = useState('');
  const [displayMessages, setDisplayMessages] = useState('');

  const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
  const [allRecords, setAllRecords] = useState([]);
  const [loading, setloading] = useState(true);

  const [allMails, setAllMails] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [mails, setMails] = useState([]);
  const [response, setResponse] = useState([]);
  const [trash, setTrash] = useState([]);

  const [buttonControl, setButtonControl] = useState(false);
  const [buttonReturn, setButtonReturn] = useState(false);

  /* All records */
  useEffect(() => {
    (async () => await tableLoad())();

    //trackIds && handleFilter();
  }, []);

  const searchDatabase = (event) => {
    event.preventDefault();
    let text = event.currentTarget.dataset.value;
    if (!text) {
      text = localStorage.getItem('text');
    }
    localStorage.setItem('text', text);
    tableLoad(text);
  }
  localStorage.removeItem("itemId");
  async function tableLoad(text) {
    let datas = `?`;
    let messages = '';

    if (text === 'AllItems') {
      datas = datas + `status[ne]=trash`;
      messages = 'ALL QUOTES AND CONTACT MAILS';
      setButtonControl(true);
      setButtonReturn(false);

    } else if (text === 'Quote') {
      datas = datas + `status[ne]=trash&typeRequest[eq]=Quote`;
      messages = 'ALL QUOTES';
      setButtonControl(true);
      setButtonReturn(false);

    } else if (text === 'Mails') {
      datas = datas + `status[ne]=trash&typeRequest[eq]=Contact`;
      messages = 'ALL CONTACT MAILS';
      setButtonControl(true);
      setButtonReturn(false);

    }
    else if (text === 'Response') {
      datas = datas + `status[eq]=response`;
      messages = 'ALL RESPONSE MAILS';
      setButtonControl(false);
      setButtonReturn(false);
    }
    else if (text === 'Trash') {
      datas = datas + `status[eq]=trash`;
      messages = 'ALL TRASH MAILS';
      setButtonControl(false);
      setButtonReturn(true);
    }
    else if (trackIds) {
      datas = datas + `trackId[eq]=` + trackIds;
      messages = 'SEARCH MAIL';
    }

    if (datas === '?') {
      datas = datas + `status[ne]=trash`;
      messages = 'ALL MAILS';
      setButtonControl(false);
    }

    setDisplayMessages(messages);
    //datas = "?pageLayout[eq]="+layoutContaner+"&pageCatigory[eq]="+catigoryContaner+"&activeStatus[eq]="+statusContaner;
    await axios.get("api/admin/v1/mail_index" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords(res.data.allData);
        setBtnLoading(buttonName);
        setloading(false);
        
      } else {
        console.log(res.data.validation_error);
      }
    });

    await axios.get("api/admin/v1/cart_quantitymails").then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllMails(res.data.allMails);
        setQuotes(res.data.quotes);
        setMails(res.data.mails);
        setTrash(res.data.trash);
        setResponse(res.data.response);

        //console.log(res.data.allMails, '$$$hhh');
      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of All records */

  /* Defualt Search Records */
  const [allRecords_02, setAllRecords_02] = useState([]); //store defualt search records

  useEffect(() => {
    (async () => await tableLoad_02())();
  }, []);

  async function tableLoad_02(text) {
    let datas = '?';
    if (text === 'AllItems') {
      datas = datas + `status[ne]=trash`;

    } else if (text === 'Quote') {
      datas = datas + `status[ne]=trash&typeRequest[eq]=Quote`;

    } else if (text === 'Mails') {
      datas = datas + `status[ne]=trash&typeRequest[eq]=Contact`;

    } else if (text === 'Response') {
      datas = datas + `status[eq]=response`;

    } else if (text === 'Trash') {
      datas = datas + `status[eq]=trash`;

    }

    if (datas === '?') {
      datas = datas + `status[ne]=trash`;
    }
    await axios.get("api/admin/v1/mail_index" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords_02(res.data.allData);

      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of Defualt Search Records */



  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newRecords = allRecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allRecords.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  /* End of Pagination */

  /* Search */
  const handleFilter = (event) => {
    let text = event.target.value;


    tableLoad_02();
    const newData = allRecords_02.filter((row) =>
      row.trackId.toString().toLowerCase().includes(text.toLowerCase())
    );
    if (event) {
      setAllRecords(newData);
      setAllRecords_02(allRecords_02);
    }
  };

  /* End of Search */
  /*Clean the Form*/
  const formCleanner = () => {
    tableLoad();
  };
  /* End Clean the Form*/


  async function updatePost(event, posts) {
    event.preventDefault();

    setEditPost(posts);
  }

  async function DeletePost(event, id) {
    event.preventDefault();

    await swal({
      title: "Are you sureee!!?",
      text: "Once deleted, you will not be able to recover this data againoo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {//used to delete the file

          axios.get("sanctum/csrf-cookie").then((response) => {
            axios.delete("api/admin/v1/delete_mail/" + id).then((res) => {
              if (res.data.status === 200) {

                swal("Deleted", res.data.message, "success");
                console.log(res.data.message);
                formCleanner();
              } else {
                swal("Oops!", res.data.message, "warning");
                console.log(res.data.validation_error);
              }
            });
          });
        } else {
          swal("Saved", "The data is safe!", "success");
        }
      });

  }

  async function ReturnPost(event, id) {
    event.preventDefault();

    await swal({
      title: "Are you sureee!!?",
      text: "Once returned, we will not be able to delete the data againoo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {//used to delete the file

          axios.get("sanctum/csrf-cookie").then((response) => {
            axios.delete("api/admin/v1/return_mail/" + id).then((res) => {
              if (res.data.status === 200) {

                swal("Retured", res.data.message, "success");
                console.log(res.data.message);
                formCleanner();
              } else {
                swal("Oops!", res.data.message, "warning");
                console.log(res.data.validation_error);
              }
            });
          });
        } else {
          swal("Saved", "The data is safe!", "success");
        }
      });

  }
  let status, color;
  //console.log(statusContaner, '$$$4');
  if (loading) {
    // Loading, Processing Payment, Please Dont Interrupt
    return <Loading message01={'Loading...'} />;
  }
  return (
    <>
      {/* modal Type, Title of the Modal Box, triger the modal, convay all the data inputs to be edithed to child */}
      <Modal modalType={'ModalMailsQuots'} titles={'MESSAGES'} trigger={true} editpost={editPost} formCleanner={formCleanner} />

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><i className="fa fa-search"></i> SEARCH MAILS</div>
              <div className="card-body">
                <div className="row m-1">
                  <div className='col-lg-12'>
                    <div className="row form-group mt-4 d-flex" style={{ fontSize: '20px' }}>
                      <Link to='' className="col-lg-3" onClick={searchDatabase} data-value='AllItems'><div className="col-12 border-end" ><FaMailBulk /> All Mails ({allMails.length})</div></Link>

                      <Link to='' className="col-lg-2" onClick={searchDatabase} data-value='Quote'><div className="col-12 border-end "><MdRequestPage /> Quote ({quotes.length})</div></Link>

                      <Link to='' className="col-lg-2" onClick={searchDatabase} data-value='Mails'><div className="col-12 border-end"><RiMailSendFill /> Mails ({mails.length})</div></Link>

                      <Link to='' className="col-lg-3" onClick={searchDatabase} data-value='Response'><div className="col-12 border-end"><PiMailboxFill /> Reply ({response.length})</div></Link>

                      <Link to='' className="col-lg-2" onClick={searchDatabase} data-value='Trash'><div className="col-12"><FaRegTrashAlt /> Trash ({trash.length})</div></Link>

                    </div>
                  </div>

                  {/* <div className='col-lg-1'>
                    <div className="form-group">

                      <Link className='btn btn-primary col-12 ms-2 mt-3' onClick={softreload} to='/admin/quotemessages'>
                        <i className='fa fa-refresh'></i>
                      </Link>

                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><MdContentPasteSearch style={{ fontSize: '20px' }} /> REQUESTED QUOTE/ CONTACT MAILS <span className="float-end me-3">{displayMessages}</span></div>
              <div className="card-body">
                <div className='row m-1'>

                  <div>
                    <div style={{ display: "flex", float: "right" }}>
                      <input
                        type="text"
                        name="searchData"
                        placeholder="...Search Track ID"
                        style={{ padding: "5px 7px" }}
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", overflowX: "scroll" }}>
                    <table className="table table-striped" style={{ width: "150%" }}>
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "2%" }}>S/N</th>
                          <th scope="col" style={{ width: "16%" }}>Action</th>
                          <th scope="col" style={{ width: "6%" }}>Track ID</th>
                          <th scope="col" style={{ width: "15%" }}>Subject</th>
                          <th scope="col" style={{ width: "15%" }}>Sender Email</th>
                          <th scope="col" style={{ width: "10%" }}>Sender Name</th>
                          <th scope="col" style={{ width: "5%" }}>Status</th>
                          <th scope="col" style={{ width: "10%" }}>Date</th>
                          <th scope="col" style={{ width: "10%" }}>Category</th>
                          <th scope="col" style={{ width: "10%" }}>Phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newRecords.map((allRecord, index) => {
                          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                          let d = new Date(allRecord.created_at);
                          let month = months[d.getMonth()];
                          let day = d.getDate();
                          let year = d.getFullYear();
                          let completDate = day + ' ' + month + ', ' + year;


                          let subjectd = allRecord.subject;
                          if (allRecord.subject === null) {
                            subjectd = 'Request For Quote';
                          }

                          let productCatd = allRecord.productCat;
                          if (allRecord.productCat === null) {
                            productCatd = 'Contact Us Message';
                          }
                          status = allRecord.status;
                          color = '';
                          if (allRecord.status === 'pending') {
                            status = 'unread';
                            color = 'fw-bolder';
                          }
                          return (
                            <tr key={index} className={color}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={allRecord.id}
                                />
                              </th>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary me-3"
                                  data-toggle="modal" data-target="#exampleModal"
                                  onClick={(event) => updatePost(event, allRecord.id)}

                                ><i className="fa fa-edit me-1"> </i>
                                  Reply
                                </button>


                                {buttonControl && <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={(event) => DeletePost(event, allRecord.id)}
                                ><i className="fa fa-trash me-1"> </i>
                                  Delete
                                </button>}

                                {buttonReturn && <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={(event) => ReturnPost(event, allRecord.id)}
                                ><i className="fa fa-undo me-1"> </i>
                                  Return
                                </button>}
                              </td>
                              <td>{allRecord.trackId}</td>
                              <td>{subjectd}</td>
                              <td>{allRecord.email}</td>
                              <td>{allRecord.names}</td>
                              <td>{status}</td>
                              <td>{completDate}</td>
                              <td>{productCatd}</td>
                              <td>{allRecord.mobile}</td>
                            </tr>
                          );
                        })}

                      </tbody>
                    </table>
                  </div>

                  <nav>
                    <ul className="pagination">
                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={prePage}>
                          Prev
                        </Link>
                      </li>

                      {number.map((n, i) => (
                        <li
                          className={`page-item ${currentPage === n ? "active" : ""}`}
                          key={i}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={nextPage}>
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  function softreload() {
    //window.location.reload();
    window.location.href = "/admin/quotemessages";
  }

  /* Pagination*/
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    if (currentPage !== firstIndex) {
      setCurrentPage(id);
    }
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* End of Pagination*/
}

export default Quotemessages
