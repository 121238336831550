import React, { useState, useEffect } from 'react';
import axios from "axios";
import signatureLogo from "../../../../assets/admin/assets/img/Signature.jpg";
import CompanyLogo from "../../../../assets/admin/assets/img/logos/smallDynamoIcon.png";
import { useSearchParams } from 'react-router-dom';

const VerifyPurchase = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [unitCost, setUnitCost] = useState('');
    const [totalCost, setTotalCost] = useState('');
    //const [uploadFile, setUploadFile] = useState('');
    const [childTrackNo, setChildTrackNo] = useState('');
    const [dateDatestramp, setDateDatestramp] = useState('');
    const [state, setState] = useState('');
    const [townCity, setTownCity] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [countryRegion, setCountryRegion] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [firstName, setFirstName] = useState('');
    //const [email, setEmail] = useState('');
    const [lastName, setLastName] = useState('');
    const [referenceNo, setReferenceNo] = useState('');
    const [hostCompAdd, setHostCompAdd] = useState('');
    const [hostCompName, setHostCompName] = useState('');

    const [itemId, setItemId] = useState(false);

    useEffect(() => {

        if (searchParams.has('ids')) {
            setItemId(searchParams.get('ids'));
        }
    }, [searchParams]);

    useEffect(() => {
        (async () => await generatPrint())();

    }, [itemId]);

    const generatPrint = async () => {

        let datas;
        datas = "?id[eq]=" + itemId;
        await axios.get("api/admin/v1/verifyRecipt" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                //let alldatad = res.data.allData;
                res.data.allData.map((dataAll, index) => {
                    setItemName(dataAll.itemName);
                    setQuantity(dataAll.quantity);
                    setUnitCost(dataAll.unitCost);
                    setTotalCost(dataAll.totalCost);
                    //setUploadFile(dataAll.uploadFile);
                    setChildTrackNo(dataAll.childTrackNo);
                    setDateDatestramp(dataAll.created_at);
                    setState(dataAll.state);
                    setTownCity(dataAll.townCity);
                    setStreetAddress(dataAll.streetAddress);
                    setCountryRegion(dataAll.countryRegion);
                    setPhoneNumber(dataAll.phoneNumber);
                    setMessage(dataAll.message);
                    setFirstName(dataAll.firstName);
                    //setEmail(dataAll.email);
                    setLastName(dataAll.lastName);
                    setReferenceNo(dataAll.referenceNo);
                    setHostCompName(dataAll.hostCompName);
                    setHostCompAdd(dataAll.hostCompAdd);

                    return console.log('res.data.bend', '$$Bend');
                });


            } else {
                console.log(res.data.validation_error);
            }
        });
    }


    function numberWithCommas(x) {
        if (!x) { x = 0; }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let d = new Date(dateDatestramp);
    let month = months[d.getMonth()];
    let day = d.getDate();
    let year = d.getFullYear();
    let completDate = day + ' ' + month + ', ' + year;

    return (
        <>
            <div className='container-lg m-10'>
                <div className='row'>
                    <div className='col-md-12 mb-3'>
                        <div className="card">
                            <div className="card-header text-white bg-info p-3"><i className="fa fa-newspaper"></i> RECEIPT VERIFICATION </div>
                            <div className="card-body">
                                <div className='row m-1'>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="5">
                                        <tr>
                                            <td style={{ fontWeight: "bold", fontSize: '45px', width: '25%', textAlign: 'left' }}>RECEIPT</td>
                                            <td style={{ width: '25%', textAlign: 'left' }}>&nbsp;</td>
                                            <td style={{ width: '25%', textAlign: 'left' }}>&nbsp;</td>
                                            <td style={{ width: '25%', textAlign: 'right' }}><img name="" src={CompanyLogo} width="100" height="100" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4"><h4>{hostCompName}</h4></td>
                                        </tr>
                                        <tr>
                                            <td>{hostCompAdd}</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td colspan="4">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4"><table width="100%" border="0" cellspacing="0" cellpadding="5">
                                                <tr>
                                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'left' }}>BILL TO</td>
                                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'left' }}>SHIP TO</td>
                                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'right' }}>INVOICE #</td>
                                                    <td bgcolor="#CCCCCC" className="fw-bold" style={{ width: '25%', textAlign: 'right' }}>{referenceNo}</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="3">
                                                        <p>{lastName} {firstName},
                                                            <br /><h5>Phone: {phoneNumber}</h5>
                                                            <br />{streetAddress},
                                                            <br /> {townCity} {state},
                                                            <br /> {countryRegion}
                                                        </p>
                                                    </td>
                                                    <td rowspan="3">
                                                        <p>{lastName} {firstName},
                                                            <br /> <h5>Phone: {phoneNumber}</h5>
                                                            <br /> {streetAddress},
                                                            <br /> {townCity} {state},
                                                            <br /> {countryRegion}
                                                        </p>
                                                    </td>
                                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }} className="fw-bold">
                                                        INVOICE DATE<br />
                                                        ITEM NUMBER
                                                    </td>
                                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }}>{completDate}<br />{childTrackNo}</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }}></td>
                                                    <td valign="top" style={{ width: '25%', textAlign: 'right' }}></td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </table></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4"><table width="100%" border="0" cellspacing="0" cellpadding="5">
                                                <tr>
                                                    <td valign="top" className="fw-bold">Message:</td>
                                                    <td>{message}</td>
                                                </tr>
                                            </table></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="5">
                                                    <tr>
                                                        <td bgcolor="#CCCCCC" className="fw-bold">QTY</td>
                                                        <td bgcolor="#CCCCCC" className="fw-bold">DESCRIPTION</td>
                                                        <td bgcolor="#CCCCCC" className="fw-bold">UNIT PRICE</td>
                                                        <td bgcolor="#CCCCCC" className="fw-bold">AMOUNT</td>
                                                    </tr>
                                                    <tr>
                                                        <td><p>{quantity}</p></td>
                                                        <td><p>{itemName}</p></td>
                                                        <td>N{numberWithCommas(unitCost)}</td>
                                                        <td>N{numberWithCommas(totalCost)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td className="fw-bold">TOTAL</td>
                                                        <td bgcolor="#CCCCCC" className="fw-bold">N{numberWithCommas(totalCost)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td><img name="" src={signatureLogo} width="100" height="100" alt="" /></td>
                                                    </tr>
                                                </table>
                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><h5>Terms &amp; Conditions</h5></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)

}
export default VerifyPurchase
