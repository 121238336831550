import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image_02 from "../../assets/admin/assets/img/no_camera-photo.png";
import { FaShippingFast } from "react-icons/fa";
import { LuShoppingBasket, LuMailSearch } from "react-icons/lu";
import { GiPayMoney } from "react-icons/gi";
import LineGraph from "./childComponents/appComponents/LineGraph";
import PieChart from "./childComponents/appComponents/PieChart";

//import BarChart from "./childComponents/appComponents/BarChart";
import axios from "axios";
const image_01 = localStorage.getItem("bend");
const Dashboard = () => {
  const [purchases, setPurchases] = useState();
  const [staffs, setStaffs] = useState();
  const [allRecords, setAllRecords] = useState([]);
  const [allRecordQ, setAllRecordQ] = useState([]);

  const [totalRequest, setTotalRequest] = useState();
  const [pendingShippment, setPendingShippment] = useState();
  const [failedPayment, setFailedPayment] = useState();
  const [shippedItems, setShippedItems] = useState();

  useEffect(() => {
    getChartData();

  }, []);

  const getChartData = async () => {
    await axios.get("sanctum/csrf-cookie").then((response) => {

      axios.get("api/admin/v1/chart_data").then((res) => {
        if (res.data.status === 200) {

          setPurchases(res.data.purchases);
        } else if (res.data.status === 401) {

        } else if (res.data.status === 500) {

        } else {
          console.log(res.data.message);
          console.log(res.data.validation_error);
        }
      });

      axios.get("api/admin/v1/staff_count").then((res) => {
        if (res.data.status === 200) {

          setStaffs(res.data.staffs);
        } else if (res.data.status === 401) {

        } else if (res.data.status === 500) {

        } else {
          console.log(res.data.message);
          console.log(res.data.validation_error);
        }
      });

      let datas = `?status[eq]=pending&paymentStatus[eq]=Paid`;
      axios.get("api/admin/v1/cart_page" + datas).then((res) => {
        // Gets the address of the backend server to be able to point the Images currectly
        if (res.data.status === 200) {
          //localStorage.setItem("bend", res.data.bend);

          setAllRecords(res.data.allData);
          console.log(res.data.bend);
        } else {
          console.log(res.data.validation_error);
        }
      });
    });

    let datasa = `?status[eq]=pending&typeRequest[eq]=Quote`;
    await axios.get("api/admin/v1/mail_index" + datasa).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecordQ(res.data.allData);

        console.log(res.data.bend);
      } else {
        console.log(res.data.validation_error);
      }
    });

    await axios.get("api/admin/v1/get_statistics").then((res) => {
      if (res.data.status === 200) {
        setTotalRequest(res.data.totalRequest);
        setPendingShippment(res.data.pendingShippment);
        setFailedPayment(res.data.failedPayment);
        setShippedItems(res.data.shippedItems);

        console.log(res.data.shippedItems, '$$$444');
      } else {
        console.log(res.data.validation_error);
      }
    });

  }

  return (
    <>
      <div className="container-lg-fluid m-3">
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="ibox bg-success color-white widget-stat">
              <div className="ibox-body">
                <h2 className="m-b-5 font-strong">{failedPayment}</h2>
                <div className="m-b-5">Failed Payment</div><i className="fa fa-money widget-stat-icon"></i>
                <div>
                  <GiPayMoney style={{ fontSize: '15px', marginRight: '5px' }} />
                  <small>All customers failed payments</small>
                </div> {/**/}
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-lg-6">

            <div className="ibox bg-info color-white widget-stat">
              <div className="ibox-body">
                <h2 className="m-b-5 font-strong">{pendingShippment}</h2>
                <div className="m-b-5">Pending Shipment</div><i className="fa fa-ship widget-stat-icon"></i>
                <div>
                  <i className="fa fa-book m-r-5"></i>
                  <small>Items waiting to be shipped</small>
                </div> {/**/}
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-lg-6">

            <div className="ibox bg-danger color-white widget-stat">
              <div className="ibox-body">
                <h2 className="m-b-5 font-strong">{shippedItems}</h2>
                <div className="m-b-5">Shipped Items</div><i className="fa fa-truck widget-stat-icon"></i>
                <div>
                  <FaShippingFast style={{ fontSize: '15px', marginRight: '5px' }} />
                  <small>Items shipped this month</small>
                </div> {/**/}
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-lg-6">

            <div className="ibox bg-primary color-white widget-stat">
              <div className="ibox-body">
                <h2 className="m-b-5 font-strong">{totalRequest}</h2>
                <div className="m-b-5">Total Items Sold</div><i className="fa fa-shopping-cart widget-stat-icon"></i>
                <div>
                  <LuShoppingBasket style={{ fontSize: '15px', marginRight: '5px' }} />
                  <small>Items sold this year</small>
                </div> {/**/}
              </div>
            </div>


          </div>
        </div>
        <div className="row py-4 px-3">
          <div className="col-lg-8 border my-3 p-4">
            <LineGraph data={purchases} labelName1='Items Purchased' labelName2='Items Shipped' text='Items Sold' subtext='List of Item Paid for and Shipped' />
          </div>

          <div className="col-xl-4 col-lg-6 my-3">
            <div className="card border">
              <div className="card-body m-2">

                <h5 className="header-title mb-4 text-secondary">QUOTES</h5>

                <div>

                  {allRecordQ.length ? (allRecordQ.map((allRecord, index) => {

                    let subjectd = allRecord.productCat;

                    return (
                      (index < 4) && (
                        <div className="row my-1">
                          <div className="col-sm-2">
                            <img src={image_02} className="rounded-circle" alt="" width={45} />
                          </div>
                          <div className="row col-sm-10">
                            <div className="col-sm-10">
                              <span className="fw-normal text-dark">{subjectd}</span>
                            </div>
                            <div className="col-sm-2 text-end">
                              <Link to={"/admin/quotemessages/" + allRecord.trackId} className="btn btn-sm text-primary"> Reply </Link>
                            </div>
                            <div className="col-sm-12">
                              <p className="fw-light text-gray mb-2"><div dangerouslySetInnerHTML={{ __html: allRecord.textMessage.substring(0, 50) + "..." }} /></p>
                            </div>
                          </div>
                        </div>)
                    );
                  })) : <>

                    <div className="col-lg-12 p-lg-4 text-center align-item-center" style={{ minHeight: "200px" }}>
                      <h3 className="align-item-center">NO NEW QUOTES</h3>
                      <LuMailSearch style={{ fontSize: '12em' }}/>
                      <Link to='/admin/quotemessages/'> <h5 className="btn btn-primary align-item-center">View Quotes</h5></Link>
                    </div>
                  </>}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="row px-3">
          <div className="col-lg-8 border my-3 p-4">
            <div className="col-lg-12"><h4>Recent Request for Purchase</h4></div>
            <div style={{ width: "100%" }}>
              {allRecords.length ? (
                <table className="table table-striped" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "10%" }}>Item Track ID</th>
                      <th scope="col" style={{ width: "5%" }}>Image</th>
                      <th scope="col" style={{ width: "20%" }}>Item Name</th>
                      <th scope="col" style={{ width: "5%" }}>Quantity</th>
                      <th scope="col" style={{ width: "10%" }}>Date</th>

                    </tr>
                  </thead>
                  <tbody>
                    {allRecords.slice(0, 5).map((allRecord, index) => {
                      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                      let d = new Date(allRecord.created_at);
                      let month = months[d.getMonth()];
                      let day = d.getDate();
                      let year = d.getFullYear();
                      let completDate = day + ' ' + month + ', ' + year;

                      return (
                        (index < 5) && (
                          <tr key={index}>
                            <td>{allRecord.childTrackNo}</td>
                            <td><img src={image_01 + allRecord.uploadFile} alt="" width={40} height={50} /></td>
                            <td><Link to="/admin/purchaseditems">{allRecord.itemName}</Link></td>
                            <td>{allRecord.quantity}</td>
                            <td>{completDate}</td>

                          </tr>
                        ));
                    })
                    }
                  </tbody>
                </table>
              ) : <>
                <div className="col-lg-12 p-lg-4 text-center align-item-center" style={{ minHeight: "200px" }}>
                  <h3 className="align-item-center">NO RECENT ITEM FOR PURCGASE</h3>
                  <LuShoppingBasket style={{ fontSize: '12em' }} />
                  <Link to='/admin/quotemessages/'> <h5 className="btn btn-primary align-item-center">View Items</h5></Link>
                </div>
              </>
              }
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 my-3">
            <div className="card border">
              <div className="card-body m-2">
                <PieChart data={staffs} labelName='Staff Number' />
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};
export default Dashboard;
