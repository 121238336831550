import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Navigate, useNavigate } from "react-router-dom"; //Outlet,
import Loading from "./components/admin/childComponents/appComponents/Loading";
const AdminPrivateRoutes = () => {

  const navigate = useNavigate("");
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    try {
      axios.get("api/admin/v1/checkingIfAuthenticated").then((res) => {
        if (res.data.status === 200) {
          setAuthenticated(true);
        }
        setloading(false);
      });
    } catch (error) {
      console.log(error.toJSON());
    }
    setAuthenticated(false);
  }, []);

  axios.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
      swal("Unauthorized Attempt! Logout!", error.response.data.message, "warning");
      navigate("/login"); //redirect to the Login page
    } else if (error.response.status === 403) {
      //Access Denied
      swal("Forbedden!", error.response.data.message, "warning");
      navigate("/admin/dashboard"); //redirect to the Dashboard page
    } else if (error.response.status === 404) {
      //Page not found
      swal("404 Error!", error.response.data.message, "warning");
      navigate("/admin/dashboard"); //redirect to the Dashboard page
    }

    return Promise.reject(error);
  });

  if (loading) {
    // Loading, Processing Payment, Please Dont Interrupt
    return <Loading message01={'Loading...'} />;
  }
  return !authenticated && <Navigate to="/login" />;
  //return authenticated ? <Outlet /> : <Navigate to="/login" />;
  /**/
};

export default AdminPrivateRoutes;
