import React, { useState, useEffect } from "react"; //
import { Link, useNavigate } from "react-router-dom"; //

import image_02 from "../../assets/admin/assets/img/no_camera-photo.png";

import applicationLogo from "../../assets/admin/assets/img/logos/dicelogo22.png";
import applicationLogo02 from "../../assets/admin/assets/img/logos/dicelogo2.png";
import axios from "axios";
import swal from "sweetalert";

const Navbar = () => {
  const [staffImage, setStaffImage] = useState('');
  const [admin_type, setAdmin_type] = useState('');
  const [allRecordQ, setAllRecordQ] = useState([]);
  const [allCount, setAllCount] = useState();

  useEffect(() => {
    setAdmin_type(localStorage.getItem("admin_type"));
    setStaffImage(localStorage.getItem('admin_passport'));

  }, []);

  let image_01 = localStorage.getItem("bend") + staffImage;

  // /* 
  //   I use this to display and remove the side menubar 
  //   (document.getElementById doesnot work directly in react)
  // */

  const [menuSize, setMenuSize] = useState(0);
  const navigate = useNavigate("");
  useEffect(() => {
    mailMessage();

  }, [menuSize]);

  async function mailMessage() {

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        let datasa = `?status[eq]=pending&typeRequest[eq]=Contact`;
        axios.get("api/admin/v1/mail_index" + datasa).then((res) => {
          // Gets the address of the backend server to be able to point the Images currectly
          if (res.data.status === 200) {
            //localStorage.setItem("bend", res.data.bend);

            setAllRecordQ(res.data.allData);
            setAllCount(res.data.allData.length);

            console.log(res.data.bend);
          } else {
            console.log(res.data.validation_error);
          }
        });
      } catch (error) {
        swal("Oops!", "Logout Failed", "error");
        console.log(error.response.data);
      }
    });
  }
  async function logoutSubmit(event) {
    event.preventDefault();

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/logout").then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);
            localStorage.removeItem("admin_passport");
            localStorage.removeItem("auth_token");
            localStorage.removeItem("admin_id");
            localStorage.removeItem('auth_name');
            localStorage.removeItem("admin_type");
            localStorage.removeItem("debitGrandTotal");
            localStorage.removeItem("creditGrandTotal");
            localStorage.removeItem("admin_signature");
            localStorage.removeItem("newGrandTotal");
            localStorage.removeItem("grandTotal");
            localStorage.removeItem("admin_authority");
            localStorage.removeItem("admin_status");
            localStorage.removeItem("allGrandTotal");
            localStorage.removeItem("selectedSearch");
            localStorage.removeItem('bend');

            /**/
            swal("Logout Successful", res.data.message, "success");
            navigate("/logout"); //redirect to the Dashboard page
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
          }
        });
      } catch (error) {
        swal("Oops!", "Logout Failed", "error");
        console.log(error.response.data);
      }
    });


    setTimeout(function () {
      setMenuSize(1);
    }, 3000);
  }
  return (
  <>
    {/* START HEADER*/}
    < header className="header" id="header">
      <div className="page-brand">
        <Link className="link" to="/admin/dashboard">
          <span className="brand">
            <span className="brand-tip">
              <img src={applicationLogo} alt="" style={{ width: "90%", height: "auto" }} />
            </span>
          </span>
          <span className="brand-mini"><img src={applicationLogo02} alt="" style={{ width: "70%", height: "auto", marginLeft: '5%' }} /></span>
        </Link>
      </div>
      <div className="flexbox flex-1">
        {/* START TOP-LEFT TOOLBAR*/}
        <ul className="nav navbar-toolbar">
          <li>
            <Link className="nav-link sidebar-toggler js-sidebar-toggler"><i className="ti-menu" /></Link>
          </li>
          <li>
            <form className="navbar-search" action="#">
              <div className="rel">
                <span className="search-icon"><i className="ti-search" /></span>
                <input className="form-control" placeholder="Search here..." />
              </div>
            </form>
          </li>
        </ul>
        {/* END TOP-LEFT TOOLBAR*/}
        {/* START TOP-RIGHT TOOLBAR*/}
        <ul className="nav navbar-toolbar">
          <li className="dropdown dropdown-inbox">
            <Link className="nav-link dropdown-toggle" data-toggle="dropdown"><i className="fa fa-envelope-o" />
              <span className="position-absolute translate-middle badge bg-danger" style={{borderRadius:"100%"}}>{allCount}</span>
            </Link>
            <ul className="dropdown-menu dropdown-menu-right dropdown-menu-media">
              <li className="dropdown-menu-header">
                <div>
                  <span><strong>{allCount} New</strong> Messages</span>
                  <Link className="pull-right" to="/admin/quotemessages">view all</Link>
                </div>
              </li>
              <li className="list-group list-group-divider scroller" data-height="240px" data-color="#71808f">
                <div>
                  {allRecordQ.map((allRecord, index) => {

                    console.log(allRecordQ.length, '$$222')
                    return (
                      (index < 4 && allRecord.trackId) && (

                        <Link to={"/admin/quotemessages/"+allRecord.trackId}className="list-group-item">
                          <div className="media">
                            <div className="media-img">
                              <img src={image_02}  width={40} alt="" />
                            </div>
                            <div className="media-body">
                              <div className="font-strong"> </div>{allRecord.names}<small className="text-muted float-right">Just now</small>
                              <div className="font-13"><div dangerouslySetInnerHTML={{ __html: allRecord.subject.substring(0, 50) + "..." }} /></div>
                            </div>
                          </div>
                        </Link>

                      )
                    );
                  })}
                  
                </div>
              </li>
            </ul>
          </li>
          
          <li className="dropdown dropdown-user">
            <Link className="nav-link dropdown-toggle link" data-toggle="dropdown">
            <img src={image_01} alt="" width="30" height="30" className='rounded-circle me-3' style={{ border: "white 2px solid" }} />
              <span />{admin_type}<i className="m-l-5" /></Link>
            <ul className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item" to="admin/profile"><i className="fa fa-user" />Profile</Link>
              <Link className="dropdown-item" to="admin/settings"><i className="fa fa-cog" />Settings</Link>
              <li className="dropdown-divider" />
              <Link className="dropdown-item" onClick={logoutSubmit}><i className="fa fa-power-off" />Logout</Link>
            </ul>
          </li>
        </ul>
        {/* END TOP-RIGHT TOOLBAR*/}
      </div>
    </header >
    {/* END HEADER*/}
  </>
  );
};
export default Navbar;