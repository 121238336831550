import React, { useState, useEffect } from 'react'
import axios from "axios";
import { TbStatusChange } from "react-icons/tb";
const ActiveStatus = (props) => {

    const [activeStatus, setActiveStatus] = useState('');
    const [allRecords, setAllRecords] = useState([]); //store defualt search records

    const [nameId, setNameId] = useState('');// The title name
    const [size, setSize] = useState(6); // The size of the field
    const [inputState, setInputState] = useState(); // The size of the field
    const [placeholder, setPlaceholder] = useState(''); // The size of the field
    const [titles, setTitles] = useState(''); // The title of the field
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setActiveStatus(props.moreDetail.activeStatus); // The value

        setNameId(props.nameId);
        setSize(props.size);
        setInputState(props.inputState);
        setPlaceholder(props.placeholder);
        setTitles(props.titles);
    }, [props.moreDetail.activeStatus, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
    /* End of Load all File with data */

    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(activeStatus);
    }, [activeStatus]);

    props.setActiveStatus(updateData);
    /* End of Send all Files data to parents */

    /* Loads the select fileds */
    useEffect(() => {
        (async () => await tableLoad())();
    }, []);

    async function tableLoad() {
        let datas = '';

        datas = "?type[eq]=activeStatus&activeStatus[eq]=Active";

        await axios.get("api/user/v1/catigory" + datas).then((res) => {
            // Gets the address of the backend server to be able to point the Images currectly
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);

                setAllRecords(res.data.allData);

            } else {
                console.log(res.data.validation_error);
            }
        });

    }

    /* End of Loads the select fileds */
    return (
        <>
            <div className={'col-lg-' + size}>
                <div className="form-group mt-3">
                    <label for="activeStatus" className="col-sm-12">
                        <TbStatusChange style={{ fontSize: '20px' }} />    {titles}
                        {/* Status */}

                    </label>
                    <div className="col-sm-12">
                        <select
                            className="form-select"

                            name={nameId}
                            disabled={inputState}
                            id={nameId}
                            placeholder={placeholder}

                            value={activeStatus}
                            onChange={(event) => {
                                setActiveStatus(event.target.value);
                            }}
                        >
                            <option value=''>Please Select Status</option>
                            {
                                allRecords.map((item, index) => {
                                    return (<option value={item.productCategory} key={item.id}>{item.productCategory}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ActiveStatus
