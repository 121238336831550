import React, { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const Camera = (props) => {
  const navigate = useNavigate("");
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc02, setImgSrc02] = useState(null);
  const videoConstraints = {
    width: 600,
    height: 700,
    facingMode: "user"
  };

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);

      const formData = new FormData();
      formData.append('uploadFile', imageSrc);

      axios.get("sanctum/csrf-cookie").then((response) => {
        try {
          axios.post("api/admin/v1/camera", formData).then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.message);

              props.setImaglocation(res.data.message);
              setImgSrc02(res.data.message);//image path
              swal("Success", "Image Captured Successfully", "success");

              navigate('/admin/dashboard'); //redirect to the home page
            } else {
              swal("Failed", res.data.message, "warning");
              console.log(res.data.message);
            }
          });
        } catch (error) {
          swal("Oops!", "Image Capture Failed", "error");
          console.log(error.response.data);
        }
      });

    }, [webcamRef, setImgSrc]);

  const deletImage = () => {
    let imgSrca = btoa(imgSrc02); //convert the link to base64 encode
    axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.delete("api/admin/v1/camera/" + imgSrca).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);

            setImgSrc('');
            swal("Success", "Image Deleted Successfully", "success");

            navigate('/admin/dashboard'); //redirect to the home page
          } else {
            swal("Failed", res.data.message, "warning");
            console.log(res.data.message);
          }
        });
      } catch (error) {
        swal("Oops!", "Image Deleting Failed", "error");
        console.log(error.response.data);
      }
    });
  }
  return (
    <>
      <div className="container">
        <div className='row'>
          <div className='col-md-12 m-3'>
            <div>WebCarm Camera</div>
            <div className="col-4" onClick={capture}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                width='100%'
                height='100%'
              /></div>
          </div>
          <div className='col-md-12 m-3'>
            <div className="col-4">
              <button className="btn btn-primary col-12" onClick={capture}>Capture photo</button>
            </div>
            <div className="col-4 mt-2">
              <button className="btn btn-danger col-12" onClick={deletImage}>Delete photo</button>
            </div>
          </div>
          <div className='col-md-12 m-3'>
            <div>WebCarm Result</div>
            <div className="col-4">
              {imgSrc && (
                <img
                  src={imgSrc} alt="test" width='100%' height='100%'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Camera;


