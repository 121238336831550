import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CAPTCHATurnStile from "../admin/childComponents/appComponents/CAPTCHATurnStile";

const buttonName = 'Request OPT';
const Register01 = () => {

  const navigate = useNavigate("");
  const [email, setEmail] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [loading, setLoading] = useState(buttonName); //to show loading process


  async function resetPassword(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);

    const datas = {
      email: email,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .post("api/admin/v1/account/validate", datas)
          .then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.message);

              localStorage.setItem('auth_email', res.data.authEmail);
              localStorage.setItem('auth_phone', res.data.authPhone);
              /**/
              swal("Success", res.data.message, "success");
              setLoading(buttonName);
              navigate("/register02"); //redirect to the home page
            } else if (res.data.status === 501) {

              swal("Caution", res.data.message, "warning");
              navigate("/register02"); //redirect to the home page
              setLoading(buttonName);
            } else {
              swal("Warning", res.data.message, "warning");
              console.log(res.data.message);
              console.log(res.data.validation_error);
              setLoading(buttonName);
            }
          });
      } catch (error) {
        swal("Oops!", "Validation Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (<>
    <h2 className="login-title">Admin Registration</h2>
    <p class="m-b-20">Enter your email address below and we'll send you an OPT to your
      registered phone and email, before you can continue.</p>
    <div className="form-group">
      <div className="input-group-icon right">
        <div className="input-icon"><i className="fa fa-envelope"></i></div>

        <input
          className="form-control"
          required
          id="email"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="name@example.com"
        />
      </div>
    </div>
    <div className="form-group">
      <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />
    </div>

    <div className="form-group">
      <div
        className="cf-turnstile"
        data-sitekey="{{Session::get('captch_key')}}"
        data-callback="javascriptCallback"
      >
      </div>
    </div>

    <div className="form-group">
      <button
        disabled={!reCaptcha}
        className="btn btn-primary col-12"
        onClick={resetPassword}
      >
        {loading}
      </button>
    </div>

    <div className="social-auth-hr">
      <span>Follow Us On</span>
    </div>
    <div className="text-center social-auth m-b-20">
      <Link className="btn btn-social-icon btn-twitter m-r-5" to="#"><i className="fa fa-twitter"></i></Link>
      <Link className="btn btn-social-icon btn-facebook m-r-5" to="#"><i className="fa fa-facebook"></i></Link>
      <Link className="btn btn-social-icon btn-google m-r-5" to="#"><i className="fa fa-google-plus"></i></Link>
      <Link className="btn btn-social-icon btn-linkedin m-r-5" to="#"><i className="fa fa-linkedin"></i></Link>
    </div>


    <div className="card-footer text-center py-3 d-flex justify-content-between">
      <Link className="small" to="/login">
        Return to login
      </Link>
      <div className="small">
        <Link to="/forgotpassword01">Forgot password?</Link>
      </div>
    </div>
  </>
  );
};
export default Register01;
