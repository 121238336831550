import React, { useEffect, useState } from 'react';
import axios from "axios";
const ActionType = (props) => {

    
    const [allCatigories, setAllCatigories] = useState([]); //store defualt search records
    const [actionType, setActionType] = useState('');

    const [nameId, setNameId] = useState('');// The title name
    const [size, setSize] = useState(6); // The size of the field
    const [inputState, setInputState] = useState(); // The size of the field
    const [placeholder, setPlaceholder] = useState(''); // The size of the field
    //const [titles, setTitles] = useState(''); // The title of the field
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setActionType(props.moreDetail.actionType); // The value

        setNameId(props.nameId);
        setSize(props.size);
        setInputState(props.inputState);
        setPlaceholder(props.placeholder);
        //setTitles(props.titles);
    }, [props.moreDetail.actionType, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
    /* End of Load all File with data */
    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(actionType);
    }, [actionType]);

    props.setActionType(updateData);
    /* End of Send all Files data to parents */
    useEffect(() => {
        (async () => await tableLoad11())();
    }, []);

    async function tableLoad11() {
        //Gets all the Product Catigories
        let datae = "?type[eq]=actionType&activeStatus[eq]=Active";

        await axios.get("api/user/v1/catigory" + datae).then((res) => {
            if (res.data.status === 200) {
                //localStorage.setItem("bend", res.data.bend);
                
                setAllCatigories(res.data.allData);
                console.log(res.data.allData, '$$$GG');
            } else {
                console.log(res.data.validation_error);
            }
        });
    }

    return (
        <>
            <div className={"col-md-"+size}>
                <select className="form-select" 
                
                name={nameId}
                        disabled={inputState}
                        id={nameId}
                        placeholder={placeholder}
                value={actionType} 
                style={{ height: "55px" }} onChange={(event) => {
                    setActionType(event.target.value);
                }} >
                    <option selected>Select Transaction Type</option>
                    {allCatigories.map((allCatigorie, index) => {

                        return <>
                            <option value={allCatigorie.productCategory}>{allCatigorie.productCategory}</option>
                        </>;
                    })}

                </select>
            </div>
        </>
    )
}

export default ActionType
