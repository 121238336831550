import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="font-13">Copyright &copy; Dice 2024 | Designed by CViewSoft Ltd</div>
      &middot;
      <Link to="#">Terms &amp; Conditions</Link>
      <div className="to-top"><i className="fa fa-angle-double-up" /></div>
    </footer>

  );
};
export default Footer;
