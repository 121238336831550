import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useNavigate
import swal from "sweetalert";
import { AiOutlineTransaction } from "react-icons/ai";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { MdSavedSearch, MdPrint } from "react-icons/md";
import StartDate from '../inputComponents/StartDate';
import EndDate from '../inputComponents/EndDate';
import Pdf from "../appComponents/Pdf";
import CompanyLogo from "../../../../assets/admin/assets/img/logos/smallDynamoIcon.png";

const ModalTransSheet = (props) => {

    const [displayMessages, setDisplayMessages] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [allRecords, setAllRecords] = useState([]);
    const [pdfPrint, setPdfPrint] = useState(false);
    const [shippingButt, setShippingButt] = useState(true);
    const [buttonControl, setButtonControl] = useState(false);
    const [dateMessage, setDateMessage] = useState('');
    const [itemId, setItemId] = useState();
    const [allCompanyDetails, setAllCompanyDetails] = useState(false);
    const [creditBalance, setCreditBalance] = useState(true);
    const [debitBalance, setDebitBalance] = useState(true);
    const [allBalance, setAllBalance] = useState(true);

    useEffect(() => {
        let searchDate = '';
        let text = '';
        if (props.direction === '2') {
            setItemId(props.itemId);
        }
        setPdfPrint(props.pdfPrint);
        (async () => await tableLoad(text, searchDate))();
    }, [props.direction, props.itemId, props.pdfPrint]);

    const searchDatabase = (event) => {
        event.preventDefault();
        let text = event.currentTarget.dataset.value;
        localStorage.setItem('selectedSearch', text);
        let searchDate = '';
        tableLoad(text, searchDate);
    }

    async function tableLoad(text, searchDate) {
        let datas = `?`;
        let messages = '';
        let acreditBalance, adebitBalance, aallBalance;

        if (text === 'All Transactions') {
            datas = datas + `paymentStatus[eq]=Paid&action[ne]=Grouped` + searchDate;
            messages = 'ALL TRANSACTIONS';
            setButtonControl(false);
            acreditBalance = true;
            adebitBalance = true;
            aallBalance = true;

        } else if (text === 'Credit') {
            datas = datas + `action[eq]=Credit&paymentStatus[eq]=Paid` + searchDate;
            messages = 'ALL CREDIT TRANSACTIONS';
            setButtonControl(false);
            acreditBalance = true;
            adebitBalance = false;
            aallBalance = false;

        } else if (text === 'Debit') {
            datas = datas + `action[eq]=Debit&paymentStatus[eq]=Paid` + searchDate;
            messages = 'ALL DEBIT TRANSACTIONS';
            setButtonControl(true);
            acreditBalance = false;
            adebitBalance = true;
            aallBalance = false;

        } else {
            datas = datas + `paymentStatus[eq]=Paid&action[ne]=Grouped` + searchDate;
            messages = 'ALL TRANSACTIONS';
            setButtonControl(false);
            acreditBalance = true;
            adebitBalance = true;
            aallBalance = true;
        }

        if (props.direction === '1') {
            setShippingButt(true);
            setButtonControl(false);
            setCreditBalance(acreditBalance);
            setDebitBalance(adebitBalance);
            setAllBalance(aallBalance);
            const neededData = { datas, acreditBalance, adebitBalance, aallBalance, messages };
            setItemId(neededData);

        } else if (props.direction === '2') {
            setShippingButt(false);
            datas = props.itemId.datas;
            messages = props.itemId.messages;
            setCreditBalance(props.itemId.acreditBalance);
            setDebitBalance(props.itemId.adebitBalance);
            setAllBalance(props.itemId.aallBalance);
            setDateMessage(localStorage.getItem('dateRang'));
        }

        setDisplayMessages(messages);

        await axios.get("api/admin/v1/cart_page" + datas).then((res) => {
            
            if (res.data.status === 200) {
                setAllRecords(res.data.allData);
                console.log(res.data.bend);

            } else if (res.data.status === 500) {
                swal("Oop!", res.data.message, "warning");
                setShippingButt(false);
                console.log(res.data.message, "Oop!");

            }
        });

        await axios.get("api/admin/v1/company_details").then((res) => {
            if (res.data.status === 200) {
                setAllCompanyDetails(res.data.allDetails);

            } else if (res.data.status === 500) {
                console.log(res.data.message, "Oop!");

            }
        });

    }

    const processDate = (event) => {
        event.preventDefault();
        let filter;
        if (startDate) {
            filter = '&created_at[gte]=' + startDate;
        }
        if (endDate) {
            filter = '&created_at[lte]=' + endDate;
        }

        let dateMessage = 'START DATE: ' + startDate + ' | END DATE: ' + endDate;
        setDateMessage(dateMessage);
        localStorage.setItem('dateRang', dateMessage);
        setEndDate('');
        setStartDate('');
        tableLoad(localStorage.getItem('selectedSearch'), filter);

    }

    const printSheet = (event) => {
        event.preventDefault();
        setShippingButt(false);
        setPdfPrint(true);
        props.formCleanners();

    }

    function numberWithCommas(x) {
        if (!x) { x = 0; }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }

    let finalValue, allGrandTotal = 0; 
    let allGrandTotalCount = 1;
    localStorage.setItem("allGrandTotal", 0);
    allRecords.map((allTransaction, index) => {
        if ((allTransaction.paymentStatus === 'Paid') && (allTransaction.action !== 'Grouped')) {
            localStorage.setItem('allGrandTotalCount', allGrandTotalCount++);
            allGrandTotal = Number(localStorage.getItem("allGrandTotal"));

            if (allTransaction.action === 'Credit') {
                finalValue = allGrandTotal + allTransaction.totalPaided;

            } else {
                finalValue = allGrandTotal - allTransaction.totalPaided;

            }

            localStorage.setItem("allGrandTotal", (finalValue));
            allGrandTotal = localStorage.getItem("allGrandTotal");
        }
        return (<></>)
    });

    let creditGrandTotal = 0;
    let creditGrandTotalCount = 1;
    localStorage.setItem("creditGrandTotal", 0);
    allRecords.map((creditTransaction, index) => {
        if ((creditTransaction.paymentStatus === 'Paid') && (creditTransaction.action === 'Credit')) {
            localStorage.setItem('creditGrandTotalCount', creditGrandTotalCount++);      
            creditGrandTotal = Number(localStorage.getItem("creditGrandTotal"));
            localStorage.setItem("creditGrandTotal", (creditGrandTotal + creditTransaction.totalPaided));
            creditGrandTotal = localStorage.getItem("creditGrandTotal");
        }
        return (<></>)
    });

    let debitGrandTotal;
    let debitGrandTotalCount = 1;
    localStorage.setItem("debitGrandTotal", 0);
    allRecords.map((debitTransaction, index) => {
        if ((debitTransaction.paymentStatus === 'Paid') && (debitTransaction.action === 'Debit')) {
            localStorage.setItem('debitGrandTotalCount', debitGrandTotalCount++);
            debitGrandTotal = Number(localStorage.getItem("debitGrandTotal"));
            localStorage.setItem("debitGrandTotal", (debitGrandTotal + debitTransaction.totalPaided));
            debitGrandTotal = localStorage.getItem("debitGrandTotal");
        }
        return (<></>)
    });

    return (
        <>

            {pdfPrint ? <Pdf docType={'ModalTransSheet'} id={itemId} referenceNo={''} /> :
                <div className='container-lg-fluid m-3'>
                    <div className='row'>

                        {shippingButt &&
                            <div className='col-md-12 mb-3'>
                                <div className="card">
                                    <div className="card-header text-white bg-info p-3"><i className="fa fa-search"></i> SEARCH TRANSACTIONS</div>
                                    <div className="card-body">
                                        <div className="row m-1">
                                            <div className='col-lg-12'>
                                                <div className="row form-group mt-4 d-flex" style={{ fontSize: '20px' }}>
                                                    <div className="row col-lg-7">
                                                        <Link to='' className="col-lg-4 text-decoration-none" onClick={searchDatabase} data-value='All Transactions'><div className="col-12 border-end" ><AiOutlineTransaction /> All Trans. ({localStorage.getItem("allGrandTotalCount")})</div></Link>

                                                        <Link to='' className="col-lg-4 text-decoration-none" onClick={searchDatabase} data-value='Credit'><div className="col-12 border-end "><GiPayMoney /> Credit ({localStorage.getItem("creditGrandTotalCount")})</div></Link>

                                                        <Link to='' className="col-lg-4 text-decoration-none" onClick={searchDatabase} data-value='Debit'><div className="col-12"><GiReceiveMoney /> Debit ({localStorage.getItem("debitGrandTotalCount")})</div></Link>
                                                    </div>
                                                    <div className="row col-lg-5">
                                                        <div className="col-lg-6">
                                                            <button className="col-sm-12 btn btn-danger my-1" onClick={processDate}><MdSavedSearch style={{ fontSize: '20px' }} /> Date Search</button>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <button className="col-sm-12 btn btn-primary my-1" onClick={printSheet}><MdPrint style={{ fontSize: '20px' }} /> Print</button>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-lg-6">
                                                            <StartDate titles='Start Date' nameId='startDate' placeholder='Choose a Start Date' size='12' inputState='' moreDetail={'props.editpost'} setStartDate={setStartDate} />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <EndDate titles='End Date' nameId='endDate' placeholder='Choose a End Date' size='12' inputState='' moreDetail={'props.editpost'} setEndDate={setEndDate} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='col-md-12 mb-3'>
                            <div className="row p-3 mb-5">

                                <div className="col-lg-2">
                                    <img name="" src={CompanyLogo} width="100" height="100" alt="" />
                                </div>
                                <div className="col-lg-8 text-center border-bottom">
                                    <div className="col-lg-12 text-center">
                                        <h2>{allCompanyDetails.name}</h2>
                                    </div>
                                    <div className="col-lg-12 text-center ">
                                        <h6>{allCompanyDetails.address}</h6>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <h5 className="text-danger">{displayMessages + ' REPORT '} </h5>
                                        <h6 className="text-danger">{dateMessage}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-2 text-end">
                                    <h6>28 May, 2024</h6>
                                </div>


                            </div>
                            <div className="card-body">
                                <div className="row m-1">

                                    <div className='col-lg-12 py-3 p-4' style={{ fontSize: '16px' }}>
                                        <div className="row">
                                            {creditBalance && <div className="col-lg-12 py-1 d-flex">
                                                <div className="col-lg-3 border-bottom">
                                                    <strong>Credit Balance:</strong>
                                                </div>
                                                <div className="col-lg-4 border-bottom">
                                                    N{numberWithCommas(localStorage.getItem("creditGrandTotal"))}
                                                </div>

                                                <div className="col-lg-3 border-bottom">
                                                    <strong>Transactions:</strong>
                                                </div>
                                                <div className="col-lg-2 border-bottom">
                                                    {localStorage.getItem("creditGrandTotalCount")}
                                                </div>
                                            </div>}

                                            {debitBalance && <div className="col-lg-12 py-1 d-flex">
                                                <div className="col-lg-3 border-bottom">
                                                    <strong>Debit Balance:</strong>
                                                </div>
                                                <div className="col-lg-4 border-bottom">
                                                    N{numberWithCommas(localStorage.getItem("debitGrandTotal"))}
                                                </div>

                                                <div className="col-lg-3 border-bottom">
                                                    <strong>Transactions:</strong>
                                                </div>
                                                <div className="col-lg-2 border-bottom">
                                                    {localStorage.getItem("debitGrandTotalCount")}
                                                </div>
                                            </div>}

                                            {allBalance && <div className="col-lg-12 py-1 d-flex">
                                                <div className="col-lg-3">
                                                    <strong>Current Balance:</strong>
                                                </div>
                                                <div className="col-lg-4">
                                                    N{numberWithCommas(localStorage.getItem("allGrandTotal"))}
                                                </div>

                                                <div className="col-lg-3">
                                                    <strong>Transactions:</strong>
                                                </div>
                                                <div className="col-lg-2">
                                                    {localStorage.getItem("allGrandTotalCount")}
                                                </div>
                                            </div>}

                                        </div>
                                    </div>

                                </div>
                                <div className='row m-1'>

                                    <div>
                                        <table className="table table-striped" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: "10%" }}>Order No.</th>
                                                    <th scope="col" style={{ width: "10%" }}>Type</th>
                                                    <th scope="col" style={{ width: "10%" }}>Credit</th>
                                                    <th scope="col" style={{ width: "10%" }}>Debit</th>
                                                    <th scope="col" style={{ width: "10%" }}>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allRecords.map((allRecord, index) => {
                                                    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                                                    let d = new Date(allRecord.created_at);
                                                    let month = months[d.getMonth()];
                                                    let day = d.getDate();
                                                    let year = d.getFullYear();
                                                    let completDate = day + ' ' + month + ', ' + year;

                                                    let grandTotal, creditAction, debitAction;
                                                    grandTotal = Number(localStorage.getItem("grandTotal"));
                                                    if (index === 0) {
                                                        grandTotal = 0;
                                                    }
                                                    switch (allRecord.action) {
                                                        case 'Credit':
                                                            localStorage.setItem("grandTotal", (grandTotal + allRecord.totalPaided));
                                                            creditAction = allRecord.totalPaided;
                                                            debitAction = 0;
                                                            break;
                                                        case 'Debit':

                                                            let operators = grandTotal - allRecord.totalPaided;
                                                            if (buttonControl) {
                                                                operators = grandTotal + allRecord.totalPaided;
                                                            }
                                                            localStorage.setItem("grandTotal", (operators));
                                                            debitAction = allRecord.totalPaided;
                                                            creditAction = 0;
                                                            break;

                                                        default:
                                                    }
                                                    grandTotal = Number(localStorage.getItem("grandTotal"));
                                                    return (
                                                        <tr key={index}>
                                                            <td>{allRecord.referenceNo}</td>
                                                            <td>{allRecord.action}</td>
                                                            <td>N{numberWithCommas(creditAction)}</td>
                                                            <td>N{numberWithCommas(debitAction)}</td>
                                                            <td>{completDate}</td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            }
        </>
    )
}

export default ModalTransSheet
