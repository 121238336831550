import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useNavigate
import Modal from "./childComponents/popups/Modal";
import Loading from "./childComponents/appComponents/Loading";
import swal from "sweetalert";
import { MdContentPasteSearch } from "react-icons/md";
import { AiOutlineTransaction, AiFillPrinter } from "react-icons/ai";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";

import { PiHandWithdraw } from "react-icons/pi";
//Purchaseditems, Quotemessages, Payments, Page404
const buttonName = 'Search Post';// Submit Button Name

const Payments = () => {

  const [editPost, setEditPost] = useState('');
  const [displayMessages, setDisplayMessages] = useState('');

  const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
  const [allRecords, setAllRecords] = useState([]);
  const [loading, setloading] = useState(true);

  // const [allTransactions, setAllTransactions] = useState([]);
  // const [creditTransactions, setCreditTransactions] = useState([]);
  // const [debitTransactions, setDebitTransactions] = useState([]);
  const [buttonControl, setButtonControl] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [titles, setTitles] = useState(false);

  /* All records */
  useEffect(() => {
    (async () => await tableLoad())();
    localStorage.removeItem('datas');
  }, []);

  const searchDatabase = (event) => {
    event.preventDefault();
    let text = event.currentTarget.dataset.value;
    tableLoad(text);
  }
  localStorage.removeItem("itemId");
  async function tableLoad(text) {
    let datas = `?`;
    let messages = '';

    if (text === 'All Transactions') {
      datas = datas + `paymentStatus[eq]=Paid&action[ne]=Grouped`;
      messages = 'ALL TRANSACTIONS';
      setButtonControl(false);

    } else if (text === 'Credit') {
      datas = datas + `action[eq]=Credit&paymentStatus[eq]=Paid`;
      messages = 'ALL CREDIT TRANSACTIONS';
      setButtonControl(false);

    } else if (text === 'Debit') {
      datas = datas + `action[eq]=Debit&paymentStatus[eq]=Paid`;
      messages = 'ALL DEBIT TRANSACTIONS';
      setButtonControl(true);

    }

    if (datas === '?') {
      datas = datas + `paymentStatus[eq]=Paid&action[ne]=Grouped`;
      messages = 'ALL TRANSACTIONS';
      setButtonControl(false);
    }

    setDisplayMessages(messages);
    //datas = "?pageLayout[eq]="+layoutContaner+"&pageCatigory[eq]="+catigoryContaner+"&activeStatus[eq]="+statusContaner;
    await axios.get("api/admin/v1/cart_page" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords(res.data.allData);
        setBtnLoading(buttonName);
        setloading(false);
        console.log(res.data.bend);
      } else if (res.data.status === 500) {
        swal("Oop!", res.data.message, "warning");
        console.log(res.data.message, "Oop!");
      }
    });

    // await axios.get("api/admin/v1/payment_quantity").then((res) => {
    //   // Gets the address of the backend server to be able to point the Images currectly
    //   if (res.data.status === 200) {
    //     //localStorage.setItem("bend", res.data.bend);

    //     setAllTransactions(res.data.allpayments);
    //     setCreditTransactions(res.data.credit);
    //     setDebitTransactions(res.data.debit);

    //   } else {
    //     console.log(res.data.validation_error);
    //   }
    // });

  }
  /* End of All records */

  /* Defualt Search Records */
  const [allRecords_02, setAllRecords_02] = useState([]); //store defualt search records

  useEffect(() => {
    (async () => await tableLoad_02())();
  }, []);

  async function tableLoad_02(text) {
    let datas = '?';
    if (text === 'All Transactions') {
      datas = datas + `paymentStatus[eq]=Paid&action[ne]=Grouped`;

    } else if (text === 'Credit') {
      datas = datas + `action[eq]=Credit&paymentStatus[eq]=Paid`;

    } else if (text === 'Debit') {
      datas = datas + `action[eq]=Debit&paymentStatus[eq]=Paid`;

    }

    if (datas === '?') {
      datas = datas + `paymentStatus[eq]=Paid&action[ne]=Grouped`;
    }
    await axios.get("api/admin/v1/cart_page" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords_02(res.data.allData);

      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of Defualt Search Records */



  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newRecords = allRecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allRecords.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  /* End of Pagination */

  /* Search */
  const handleFilter = (event) => {
    let text = event.target.value;
    tableLoad_02();
    const newData = allRecords_02.filter((row) =>
      row.referenceNo.toString().toLowerCase().includes(text.toLowerCase())
    );
    if (event) {
      setAllRecords(newData);
      setAllRecords_02(allRecords_02);
    }
  };

  /* End of Search */
  /*Clean the Form*/
  const formCleanner = () => {
    tableLoad();
  };
  /* End Clean the Form*/


  async function printTransaction(event, posts) {
    event.preventDefault();
    setModalType('ModalPurchItems');
    setTitles('PRINT ITEM RECEIPT');
    setEditPost(posts);
  }

  async function withDrawFunds(event) {
    event.preventDefault();
    setModalType('ModalWithDraw');
    setTitles('CREDIT/ WITHDRAW FUNDS');
    setEditPost('posts');
  }

  async function printTransactionSheet(event) {
    event.preventDefault();
    setModalType('ModalTransSheet');
    setTitles('PRINT TRANSACTIONS');
    setEditPost('posts');
  }

  function numberWithCommas(x) {
    if (!x) { x = 0; }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //console.log(statusContaner, '$$$4');
  if (loading) {
    // Loading, Processing Payment, Please Dont Interrupt
    return <Loading message01={'Loading...'} />;
  }
  let finalValue, allGrandTotal = 0;
  localStorage.setItem("allGrandTotal", 0);
  let allGrandTotalCount = 1;
  allRecords.map((allTransaction, index) => {
    if ((allTransaction.paymentStatus === 'Paid') && (allTransaction.action !== 'Grouped')) {
      allGrandTotal = Number(localStorage.getItem("allGrandTotal"));
      localStorage.setItem('allGrandTotalCount', allGrandTotalCount++);
      if (allTransaction.action === 'Credit') {
        finalValue = allGrandTotal + allTransaction.totalPaided;
      } else {
        finalValue = allGrandTotal - allTransaction.totalPaided;
      }
      localStorage.setItem("allGrandTotal", (finalValue));
      allGrandTotal = localStorage.getItem("allGrandTotal");
    }
    return (<></>)
  });

  let creditGrandTotal = 0;
  let creditGrandTotalCount = 1;
  localStorage.setItem("creditGrandTotal", 0);
  allRecords.map((creditTransaction, index) => {
    if ((creditTransaction.paymentStatus === 'Paid') && (creditTransaction.action === 'Credit')) {
      localStorage.setItem('creditGrandTotalCount', creditGrandTotalCount++); 
      creditGrandTotal = Number(localStorage.getItem("creditGrandTotal"));
      localStorage.setItem("creditGrandTotal", (creditGrandTotal + creditTransaction.totalPaided));
      creditGrandTotal = localStorage.getItem("creditGrandTotal");
    }
    return (<></>)
  });

  let debitGrandTotal;
  let debitGrandTotalCount = 1;
  localStorage.setItem("debitGrandTotal", 0);
  allRecords.map((debitTransaction, index) => {
    if ((debitTransaction.paymentStatus === 'Paid') && (debitTransaction.action === 'Debit')) {
      localStorage.setItem('debitGrandTotalCount', debitGrandTotalCount++);
      debitGrandTotal = Number(localStorage.getItem("debitGrandTotal"));
      localStorage.setItem("debitGrandTotal", (debitGrandTotal + debitTransaction.totalPaided));
      debitGrandTotal = localStorage.getItem("debitGrandTotal");
    }
    return (<></>)
  });

  return (
    <>
      {/* modal Type, Title of the Modal Box, triger the modal, convay all the data inputs to be edithed to child */}
      <Modal modalType={modalType} titles={titles} trigger={true} editpost={editPost} formCleanner={formCleanner} />

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><i className="fa fa-search"></i> SEARCH TRANSACTIONS</div>
              <div className="card-body">
                <div className="row m-1">
                  <div className='col-lg-10'>
                    <div className="row form-group mt-4 d-flex" style={{ fontSize: '20px' }}>
                      <Link to='' className="col-lg-4" onClick={searchDatabase} data-value='All Transactions'><div className="col-12 border-end" ><AiOutlineTransaction /> All Trans. ({localStorage.getItem("allGrandTotalCount")})</div></Link>

                      <Link to='' className="col-lg-4" onClick={searchDatabase} data-value='Credit'><div className="col-12 border-end "><GiPayMoney /> Credit ({localStorage.getItem("creditGrandTotalCount")})</div></Link>

                      <Link to='' className="col-lg-4" onClick={searchDatabase} data-value='Debit'><div className="col-12"><GiReceiveMoney /> Debit ({localStorage.getItem("debitGrandTotalCount")})</div></Link>

                    </div>
                  </div>

                  <div className='col-lg-2'>
                    <div className="form-group">

                      <Link className='btn btn-primary col-12 ms-2 mt-3' onClick={softreload} to='/admin/payments'>
                        <i className='fa fa-refresh'></i>
                      </Link>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><MdContentPasteSearch style={{ fontSize: '20px' }} /> SUMMARY SHEET</div>
              <div className="card-body">
                <div className="row m-1">

                  <div className='col-lg-8 py-3 p-4' style={{ fontSize: '20px' }}>
                    <div className="row col-lg">
                      <div className="col-lg-3 border-bottom">
                        Credit Balance:
                      </div>
                      <div className="col-lg-4 border-bottom">
                        <h5>₦{numberWithCommas(localStorage.getItem("creditGrandTotal"))}</h5>
                      </div>

                      <div className="col-lg-3 border-bottom">
                        Transactions:
                      </div>
                      <div className="col-lg-2 border-bottom">
                        <h5>{localStorage.getItem("creditGrandTotalCount")}</h5>
                      </div>

                      <div className="col-lg-3 border-bottom">
                        Debit Balance:
                      </div>
                      <div className="col-lg-4 border-bottom">
                        <h5> ₦{numberWithCommas(localStorage.getItem("debitGrandTotal"))}</h5>
                      </div>

                      <div className="col-lg-3 border-bottom">
                        Transactions:
                      </div>
                      <div className="col-lg-2 border-bottom">
                        <h5>{localStorage.getItem("debitGrandTotalCount")}</h5>
                      </div>

                      <div className="col-lg-3">
                        Current Balance:
                      </div>
                      <div className="col-lg-4">
                        <h5> ₦{numberWithCommas(localStorage.getItem("allGrandTotal"))}</h5>
                      </div>

                      <div className="col-lg-3">
                        Transactions:
                      </div>
                      <div className="col-lg-2">
                        <h5>{localStorage.getItem("allGrandTotalCount")}</h5>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <button className="col-lg-12 btn btn-danger my-3" onClick={withDrawFunds} data-toggle="modal" data-target="#exampleModal">
                        <h5><div className="col-lg-12"><PiHandWithdraw style={{ fontSize: '20px' }} />    Withdraw Funds
                        </div></h5>
                      </button>

                      <button className="col-lg-12 btn btn-primary" onClick={printTransactionSheet} data-toggle="modal" data-target="#exampleModal">
                        <h5><div className="col-lg-12"><AiFillPrinter style={{ fontSize: '20px' }} />    Print Sheet
                        </div></h5>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><MdContentPasteSearch style={{ fontSize: '20px' }} /> STATEMENT TRANSACTIONS  <span className="float-end me-3">{displayMessages}</span></div>
              <div className="card-body">
                <div className='row m-1'>

                  <div>
                    <div style={{ display: "flex", float: "right" }}>
                      <input
                        type="text"
                        name="searchData"
                        placeholder="...Search Order Number"
                        style={{ padding: "5px 7px" }}
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", overflowX: "scroll" }}>
                    <table className="table table-striped" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "5%" }}>Select</th>
                          <th scope="col" style={{ width: "20%" }}>Action</th>
                          <th scope="col" style={{ width: "10%" }}>Order No.</th>
                          <th scope="col" style={{ width: "10%" }}>Type</th>
                          <th scope="col" style={{ width: "10%" }}>Credit</th>
                          <th scope="col" style={{ width: "10%" }}>Debit</th>
                          <th scope="col" style={{ width: "15%" }}>Balance</th>
                          <th scope="col" style={{ width: "10%" }}>Date</th>

                        </tr>
                      </thead>
                      <tbody>
                        {newRecords.map((allRecord, index) => {
                          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                          let d = new Date(allRecord.created_at);
                          let month = months[d.getMonth()];
                          let day = d.getDate();
                          let year = d.getFullYear();
                          let completDate = day + ' ' + month + ', ' + year;



                          //localStorage.setItem("bend", res.data.bend);

                          let grandTotal, creditAction, debitAction;
                          grandTotal = Number(localStorage.getItem("grandTotal"));
                          if (index === 0) {
                            grandTotal = 0;
                          }
                          switch (allRecord.action) {
                            case 'Credit':
                              localStorage.setItem("grandTotal", (grandTotal + allRecord.totalPaided));
                              creditAction = allRecord.totalPaided;
                              debitAction = 0;
                              break;
                            case 'Debit':

                              let operators = grandTotal - allRecord.totalPaided;
                              if (buttonControl) {
                                operators = grandTotal + allRecord.totalPaided;
                              }
                              localStorage.setItem("grandTotal", (operators));
                              debitAction = allRecord.totalPaided;
                              creditAction = 0;
                              break;

                            default:
                          }
                          grandTotal = Number(localStorage.getItem("grandTotal"));
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={allRecord.id}
                                />
                              </th>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary me-3"
                                  data-toggle="modal" data-target="#exampleModal"
                                  onClick={(event) => printTransaction(event, allRecord.referenceNo)}

                                ><AiFillPrinter style={{ fontSize: '20px' }} />
                                  Details/ Print
                                </button>

                              </td>
                              <td>{allRecord.referenceNo}</td>
                              <td>{allRecord.action}</td>
                              <td>₦{numberWithCommas(creditAction)}</td>
                              <td>₦{numberWithCommas(debitAction)}</td>

                              <td><strong>₦{numberWithCommas(grandTotal)}</strong></td>
                              <td>{completDate}</td>
                            </tr>
                          );
                        })}

                      </tbody>
                    </table>
                  </div>

                  <nav>
                    <ul className="pagination">
                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={prePage}>
                          Prev
                        </Link>
                      </li>

                      {number.map((n, i) => (
                        <li
                          className={`page-item ${currentPage === n ? "active" : ""}`}
                          key={i}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={nextPage}>
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  function softreload() {
    window.location.reload();
  }

  /* Pagination*/
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    if (currentPage !== firstIndex) {
      setCurrentPage(id);
    }
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* End of Pagination*/
}

export default Payments
