import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useNavigate
import swal from "sweetalert";
import Modal from "./childComponents/popups/Modal";
import Loading from "../admin/childComponents/appComponents/Loading";

import { MdOutlineAddShoppingCart, MdContentPasteSearch } from "react-icons/md";
import { FaShip } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { FaPersonDigging } from "react-icons/fa6";
//Purchaseditems, Quotemessages, Payments, Page404
const buttonName = 'Search Post';// Submit Button Name
const image_01 = localStorage.getItem("bend");

const Purchaseditems = () => {

  const [editPost, setEditPost] = useState('');
  const [displayMessages, setDisplayMessages] = useState('');

  const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
  const [allRecords, setAllRecords] = useState([]);
  const [loading, setloading] = useState(true);

  const [allItemsRecords, setAllItemsRecords] = useState([]);
  const [pendingRecords, setPendingRecords] = useState([]);
  const [paidRecords, setPaidRecords] = useState([]);
  const [shippedRecords, setShippedRecords] = useState([]);
  const [buttonControl, setButtonControl] = useState(false);

  /* All records */
  useEffect(() => {
    (async () => await tableLoad())();
  }, []);

  const searchDatabase = (event) => {
    event.preventDefault();
    let text = event.currentTarget.dataset.value;
    tableLoad(text);
  }
  localStorage.removeItem("itemId");
  async function tableLoad(text) {
    let datas = `?`;
    let messages = '';

    if (text === 'All Items') {
      datas = datas + `cartUsersId[ne]=null&paymentStatus[ne]=null`;
      messages = 'ALL CART ITEMS';
      setButtonControl(false);

    } else if (text === 'Pending') {
      datas = datas + `status[eq]=pending&paymentStatus[eq]=Unpaid`;
      messages = 'ALL CART ITEMS WITH PENDING PURCHASE';
      setButtonControl(true);
    } else if (text === 'Paid') {
      datas = datas + `status[eq]=pending&paymentStatus[eq]=Paid`;
      messages = 'ALL CART ITEMS PAID FOR';
      setButtonControl(false);

    } else if (text === 'Shipped') {
      datas = datas + `status[eq]=shipped&paymentStatus[eq]=Paid`;
      messages = 'ALL CART ITEMS SHIPPED';
      setButtonControl(false);
    }

    if (datas === '?') {
      datas = datas + `cartUsersId[ne]=null&paymentStatus[ne]=null`;
      messages = 'ALL CART ITEMS';
      setButtonControl(false);
    }

    setDisplayMessages(messages);
    //datas = "?pageLayout[eq]="+layoutContaner+"&pageCatigory[eq]="+catigoryContaner+"&activeStatus[eq]="+statusContaner;
    await axios.get("api/admin/v1/cart_page" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords(res.data.allData);
        setBtnLoading(buttonName);
        setloading(false);
        console.log(res.data.bend);
      } else {
        console.log(res.data.validation_error);
      }
    });

    await axios.get("api/admin/v1/cart_quantityproducts").then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllItemsRecords(res.data.allItems);
        setPendingRecords(res.data.pending);
        setPaidRecords(res.data.paid);
        setShippedRecords(res.data.shipped);

        console.log(res.data.bend);
      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of All records */

  /* Defualt Search Records */
  const [allRecords_02, setAllRecords_02] = useState([]); //store defualt search records

  useEffect(() => {
    (async () => await tableLoad_02())();
  }, []);

  async function tableLoad_02(text) {
    let datas = '?';
    if (text === 'All Items') {
      datas = datas + `cartUsersId[ne]=null&paymentStatus[ne]=null`;

    } else if (text === 'Pending') {
      datas = datas + `status[eq]=pending&paymentStatus[eq]=Unpaid`;

    } else if (text === 'Paid') {
      datas = datas + `status[eq]=pending&paymentStatus[eq]=Paid`;

    } else if (text === 'Shipped') {
      datas = datas + `status[eq]=shipped&paymentStatus[eq]=Paid`;

    }

    if (datas === '?') {
      datas = datas + `cartUsersId[ne]=null&paymentStatus[ne]=null`;
    }
    await axios.get("api/admin/v1/cart_page" + datas).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setAllRecords_02(res.data.allData);

      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of Defualt Search Records */



  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newRecords = allRecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allRecords.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  /* End of Pagination */

  /* Search */
  const handleFilter = (event) => {
    let text = event.target.value;
    tableLoad_02();
    const newData = allRecords_02.filter((row) =>
      row.referenceNo.toString().toLowerCase().includes(text.toLowerCase())
    );
    if (event) {
      setAllRecords(newData);
      setAllRecords_02(allRecords_02);
    }
  };

  /* End of Search */
  /*Clean the Form*/
  const formCleanner = () => {
    tableLoad();
  };
  /* End Clean the Form*/


  async function updatePost(event, posts) {
    event.preventDefault();

    setEditPost(posts);
  }
  
  async function DeletePost(event, id) {
    event.preventDefault();

    await swal({
      title: "Are you sureee!!?",
      text: "Once deleted, you will not be able to recover this data againoo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {//used to delete the file

          axios.get("sanctum/csrf-cookie").then((response) => {
            axios.delete("api/admin/v1/cart_page/" + id).then((res) => {
              if (res.data.status === 200) {

                swal("Deleted", res.data.message, "success");
                console.log(res.data.message);
                formCleanner();
              } else {
                swal("Oops!", res.data.message, "warning");
                console.log(res.data.validation_error);
              }
            });
          });
        } else {
          swal("Saved", "The data is safe!", "success");
        }
      });

  }
  function numberWithCommas(x) {
    if (!x) { x = 0; }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //console.log(statusContaner, '$$$4');
  if (loading) {
    // Loading, Processing Payment, Please Dont Interrupt
    return <Loading message01={'Loading...'} />;
  }
  return (
    <>
      {/* modal Type, Title of the Modal Box, triger the modal, convay all the data inputs to be edithed to child */}
      <Modal modalType={'ModalPurchItem'} titles={'SHIP ITEM'} trigger={true} editpost={editPost} formCleanner={formCleanner} />

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><i className="fa fa-search"></i> SEARCH CART ITEMS</div>
              <div className="card-body">
                <div className="row m-1">
                  <div className='col-lg-10'>
                    <div className="row form-group mt-4 d-flex" style={{ fontSize: '20px' }}>
                      <Link to='' className="col-lg-3" onClick={searchDatabase} data-value='All Items'><div className="col-12 border-end" ><MdOutlineAddShoppingCart /> All Items ({allItemsRecords.length})</div></Link>

                      <Link to='' className="col-lg-3" onClick={searchDatabase} data-value='Pending'><div className="col-12 border-end "><GiPayMoney /> Failed To Pay ({pendingRecords.length})</div></Link>

                      <Link to='' className="col-lg-3" onClick={searchDatabase} data-value='Paid'><div className="col-12 border-end"><FaPersonDigging /> Processing ({paidRecords.length})</div></Link>

                      <Link to='' className="col-lg-3" onClick={searchDatabase} data-value='Shipped'><div className="col-12"><FaShip /> Shipped ({shippedRecords.length})</div></Link>

                    </div>
                  </div>

                  <div className='col-lg-2'>
                    <div className="form-group">

                      <Link className='btn btn-primary col-12 ms-2 mt-3' onClick={softreload} to='/admin/purchaseditems'>
                        <i className='fa fa-refresh'></i>
                      </Link>
      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><MdContentPasteSearch style={{ fontSize: '20px' }} /> CART ITEMS <span className="float-end me-3">{displayMessages}</span></div>
              <div className="card-body">
                <div className='row m-1'>

                  <div>
                    <div style={{ display: "flex", float: "right" }}>
                      <input
                        type="text"
                        name="searchData"
                        placeholder="...Search Order Number"
                        style={{ padding: "5px 7px" }}
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", overflowX: "scroll" }}>
                    <table className="table table-striped" style={{ width: "150%" }}>
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "5%" }}>Select</th>
                          <th scope="col" style={{ width: "15%" }}>Action</th>
                          <th scope="col" style={{ width: "10%" }}>Order Number</th>
                          <th scope="col" style={{ width: "10%" }}>Shipment</th>
                          <th scope="col" style={{ width: "5%" }}>Image</th>
                          <th scope="col" style={{ width: "10%" }}>Item Name</th>
                          <th scope="col" style={{ width: "10%" }}>Unit Cost</th>
                          <th scope="col" style={{ width: "5%" }}>Quantity</th>
                          <th scope="col" style={{ width: "10%" }}>Total Cost</th>
                          <th scope="col" style={{ width: "10%" }}>Date</th>
                          <th scope="col" style={{ width: "10%" }}>Item Track ID</th>

                        </tr>
                      </thead>
                      <tbody>
                        {newRecords.map((allRecord, index) => {
                          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                          let d = new Date(allRecord.created_at);
                          let month = months[d.getMonth()];
                          let day = d.getDate();
                          let year = d.getFullYear();
                          let completDate = day + ' ' + month + ', ' + year;

                          return (
                            <tr key={index}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={allRecord.id}
                                />
                              </th>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary me-3"
                                  data-toggle="modal" data-target="#exampleModal"
                                  onClick={(event) => updatePost(event, allRecord.id)}

                                ><i className="fa fa-edit me-1"> </i>
                                  Receipt
                                </button>


                                {buttonControl && <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={(event) => DeletePost(event, allRecord.id)}
                                ><i className="fa fa-trash me-1"> </i>
                                  Delete
                                </button> }
                              </td>
                              <td>{allRecord.referenceNo}</td>
                              <td>{allRecord.status}</td>
                              <td><img src={image_01 + allRecord.uploadFile} alt="" width={70} height={80} /></td>
                              <td>{allRecord.itemName}</td>
                              <td>₦{numberWithCommas(allRecord.unitCost)}</td>
                              <td>{allRecord.quantity}</td>
                              <td><strong>₦{numberWithCommas(allRecord.totalCost)}</strong></td>
                              <td>{completDate}</td>
                              <td>{allRecord.childTrackNo}</td>
                            </tr>
                          );
                        })}

                      </tbody>
                    </table>
                  </div>

                  <nav>
                    <ul className="pagination">
                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={prePage}>
                          Prev
                        </Link>
                      </li>

                      {number.map((n, i) => (
                        <li
                          className={`page-item ${currentPage === n ? "active" : ""}`}
                          key={i}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={nextPage}>
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  function softreload() {
    window.location.reload();
  }

  /* Pagination*/
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    if (currentPage !== firstIndex) {
      setCurrentPage(id);
    }
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* End of Pagination*/
}

export default Purchaseditems
