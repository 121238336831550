import React, { useEffect, useState } from 'react';

const GPS = (props) => {

  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLon(position.coords.longitude);
    });

    props.setLogi(lat);
    props.setLati(lon);

  });

  return (
    <div>
      {/* <div>Longitude: {lat}</div><br /><div>Latitude: {lon}</div> */}
    </div>
  )
}
export default GPS