import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import ModalPost from "./ModalPost";
import ModalPurchItem from "./ModalPurchItem";
import ModalPurchItems from "./ModalPurchItems";
import ModalMailsQuots from "./ModalMailsQuots";
import ModalWithDraw from "./ModalWithDraw";
import ModalTransSheet from "./ModalTransSheet";
const Modal = (props) => {

    const [showModal, setShowModal] = useState(false);// activates the popup modal
    const [closeModal, setCloseModal] = useState(false);// closes the popup modal
    const [editposts, setEditposts] = useState(false);// closes the popup modal
    const closeModalRef = useRef();

    useEffect(() => {
        setShowModal(props.trigger);
        setEditposts(props.editpost);
    }, [props.trigger, props.editpost]);
    //props.editpost

    let tagName;

    switch (props.modalType) {
        case "ModalPost":
            tagName = <ModalPost editposts={editposts} setCloseModal={setCloseModal} formCleanners={props.formCleanner} />;
            break;
        case "ModalWithDraw":
            tagName = <ModalWithDraw title={props.titles} setCloseModal={setCloseModal} formCleanners={props.formCleanner} />;
            break;
        case "ModalPurchItem":
            tagName = <ModalPurchItem itemId={editposts} setCloseModal={setCloseModal} formCleanners={props.formCleanner} pdfPrint={false} direction={'1'} />;
            break;
        case "ModalTransSheet":
            tagName = <ModalTransSheet itemId={''}  title={props.titles} setCloseModal={setCloseModal} formCleanners={props.formCleanner} pdfPrint={false} direction={'1'} />;
            break;
        case "ModalPurchItems":
            tagName = <ModalPurchItems itemId={editposts} setCloseModal={setCloseModal} formCleanners={props.formCleanner} pdfPrint={false} direction={'1'} />;
            break;
        case "ModalMailsQuots":
            tagName = <ModalMailsQuots itemId={editposts} setCloseModal={setCloseModal} formCleanners={props.formCleanner} readMail={true} direction={'1'} />;
            break;

        default:
            tagName = <ModalPost editposts={editposts} setCloseModal={setCloseModal} formCleanners={props.formCleanner} />;
    }

    if (closeModal === true) {
        closeModalRef.current?.click();
        setCloseModal(false);
    }

    return (showModal) ? (
        <>
            {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                Launch demo modal
            </button> */}
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{props.titles}</h5>
                            <button type="button" className="close" data-dismiss="modal" ref={closeModalRef} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='container-lg-fluid m-3'>


                                {tagName}


                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link type="button" className="btn btn-secondary" to="#" data-dismiss="modal" ref={closeModalRef}>Close</Link>
                        </div>

                    </div>
                </div>
            </div >

        </>
    ) : "";

}

export default Modal
