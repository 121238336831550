import React from "react";
import "../../assets/admin/css/styles.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import AdminPrivateRoutes from "../../AdminPrivateRoutes";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
//import Settings from "./Settings";

import Dashboard from "../../components/admin/Dashboard";
import Students from "../../components/admin/Students";
import Post from "../../components/admin/Post";
import AllPost from "../../components/admin/AllPost";
import Purchaseditems from "../../components/admin/Purchaseditems";
import Quotemessages from "../../components/admin/Quotemessages";
import Payments from "../../components/admin/Payments";
import Page404 from "../../components/admin/Page404";

/* Added Components */
import Camera from "../../components/admin/childComponents/appComponents/Camera";
import Crop from "../../components/admin/childComponents/appComponents/Crop";
import GPS from "../../components/admin/childComponents/appComponents/GPS";
import QrCodes from "../../components/admin/childComponents/appComponents/QrCodes";
import Pdf from "../../components/admin/childComponents/appComponents/Pdf";
import QrScanner from "../../components/admin/childComponents/appComponents/QrScanner";
/* End of Added Components */


const Masterwebpage = (props) => {
  let tagName;

  switch (props.name) {
    case "Dashboard":
      tagName = <Dashboard />;
      break;
    case "Post":
      tagName = <Post />;
      break;
    case "AllPost":
      tagName = <AllPost />;
      break;
    case "Students":
      tagName = <Students />;
      break;
    case "Purchaseditems":
      tagName = <Purchaseditems />;
      break;
    case "Quotemessages":
      tagName = <Quotemessages />;
      break;
    case "Payments":
      tagName = <Payments />;
      break;
    case "Page404":
      tagName = <Page404 />;
      break;

    /* Added Components */
    case "GPS":
      tagName = <GPS />;
      break;
    case "Crop":
      tagName = <Crop />;
      break;
    case "Pdf":
      tagName = <Pdf />;
      break;
    case "QrCodes":
      tagName = <QrCodes />;
      break;
    case "QrScanner":
      tagName = <QrScanner />;
      break;
    case "Camera":
      tagName = <Camera />;
      break;
    /* End of Added Components */

    default:
      tagName = <Dashboard />;
  }

  return (
    <>
      <AdminPrivateRoutes />
      <div id='page-wrapper' className="page-wrapper">
        <Navbar />
        <Sidebar />
        <div className="content-wrapper bg-white">
          <div className="page-content fade-in-up">
            {tagName}
          </div>
          <Footer />
        </div>
      </div>
      {/* <Settings /> */}
    </>
  );
};
export default Masterwebpage;
