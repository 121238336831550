import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CAPTCHATurnStile from "../admin/childComponents/appComponents/CAPTCHATurnStile";

const buttonName = 'Create An Account';
const Register02 = () => {

  const navigate = useNavigate("");

  const [otpToken, setOtptoken] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [sex, setSex] = useState();
  const [title, setTitle] = useState();
  const [email, setEmail] = useState(localStorage.getItem('auth_email'));
  const [phone, setPhone] = useState(localStorage.getItem('auth_phone'));
  const [password, setPassword] = useState();
  const [repeatpassword, setRepeatpassword] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [agree, setAgree] = useState();
  const [loading, setLoading] = useState(buttonName); //to show loading process

  async function createAccount(event) {

    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    const datas = {
      otpToken: otpToken,
      firstName: firstName,
      lastName: lastName,
      sex: sex,
      title: title,
      email: email,
      phone: phone,
      password: password,
      repeatpassword: repeatpassword,
      agree: agree,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .post("api/admin/v1/register/create", datas)
          .then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.message);
              /*
              localStorage.setItem('auth_token', res.data.token);
              localStorage.setItem('auth_name', res.data.firstName);
              */
              swal("Success", res.data.message, "success");
              setLoading(buttonName);
              navigate("/login"); //redirect to the home page
            } else {
              swal("Warning", res.data.message, "warning");
              console.log(res.data.message);
              console.log(res.data.validation_error);
              setLoading(buttonName);
            }
          });
      } catch (error) {
        swal("Oops!", "Registration Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (<>
    <h2 className="login-title">Admin Registration</h2>
    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-user"></i></div>
            <input
              className="form-control"
              id="firstName"
              type="text"
              value={firstName}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              placeholder="Your first name"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-user"></i></div>
            <input
              className="form-control"
              id="lastName"
              type="text"
              value={lastName}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              placeholder="Your last name"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-user"></i></div>
            <select
              name="sex"
              id="sex"
              className="form-control"
              value={sex}
              onChange={(event) => {
                setSex(event.target.value);
              }}
            >
              <option value="">-- Select a Gender --</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>

          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-user"></i></div>
            <select
              name="title"
              id="title"
              className="form-control"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            >
              <option value="">-- Select a Title --</option>
              <option value="Miss">Miss</option>
              <option value="Mrs">Mrs</option>
              <option value="Mr">Mr</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-envelope"></i></div>
            <input
              className="form-control"
              required
              disabled
              id="email"
              type="text"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Enter your Email"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-phone"></i></div>
            <input
              className="form-control"
              required
              disabled
              id="phone"
              type="text"
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              placeholder="Your Phone Number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-lock"></i></div>
            <input
              className="form-control"
              required
              id="password"
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              placeholder="Create a password"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-lock"></i></div>
            <input
              className="form-control"
              required
              id="repeatpassword"
              value={repeatpassword}
              onChange={(event) => {
                setRepeatpassword(event.target.value);
              }}
              type="password"
              placeholder="Confirm password"
            />
          </div>
          </div>
        </div>
      </div >
      <div class="row">
        <div class="col-12">
          <div className="form-group">
            <div className="input-group-icon right">
              <div className="input-icon"><i className="fa fa-phone"></i></div>
              <input
                className="form-control"
                id="otpToken"
                type="number"
                value={otpToken}
                onChange={(event) => {
                  setOtptoken(event.target.value);
                }}
                placeholder="Paste the OTP"
              />
            </div>
          </div>
        </div>
        <div className="form-group px-5">
          <input
            className="form-check-input"
            id="agree"
            type="checkbox"
            name="agree"
            value='on'
            onChange={(event) => {
              setAgree(event.target.value);
            }}
          />
          <label className="form-check-label" for="agree">
            I agree the terms and policy
          </label>
        </div>
        <div className="form-group">
          <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />
        </div>
        <div className="form-group">
          <button
            disabled={!reCaptcha}
            className="btn btn-primary btn-block"
            onClick={createAccount}
          >
            {loading}
          </button>
        </div>
      </div>

      <div className="social-auth-hr">
        <span>Follow Us On</span>
      </div>
      <div className="text-center social-auth m-b-20">
        <Link className="btn btn-social-icon btn-twitter m-r-5" to="#"><i className="fa fa-twitter"></i></Link>
        <Link className="btn btn-social-icon btn-facebook m-r-5" to="#"><i className="fa fa-facebook"></i></Link>
        <Link className="btn btn-social-icon btn-google m-r-5" to="#"><i className="fa fa-google-plus"></i></Link>
        <Link className="btn btn-social-icon btn-linkedin m-r-5" to="#"><i className="fa fa-linkedin"></i></Link>
      </div>


      <div className="card-footer text-center py-3 d-flex justify-content-between">
        <Link className="small" to="/login">
          Have an account? Go to login
        </Link>
        <div className="small">
          <Link to="/forgotpassword01">Forgot password?</Link>
        </div>
      </div>

    </>
    );
};

    export default Register02;
