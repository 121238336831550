import React from "react";
import { Routes, Route } from "react-router-dom";
import Masterwebpage from "./layouts/admin/Masterwebpage";
import Masterauthwebpage from "./layouts/authAdmin/Masterauthwebpage";
import axios from "axios";
import VerifyPurchase from './components/admin/childComponents/verify/VerifyPurchase';

//let defaultsBend = "http://127.0.0.1:8000/";
let defaultsBend = "https://bend.savvypremier.ng/";

axios.defaults.baseURL = defaultsBend;
axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token
    ? `Bearer ${token}`
    : "";
  return config;
});
function App() {

  return (
    
      <Routes>

        {/* Admin Login Section */}
        <Route path="/" element={<Masterauthwebpage name="Login" />} />
        <Route path="/login" element={<Masterauthwebpage name="Login" />} />
        <Route path="/logout" element={<Masterauthwebpage name="Login" />} />
        <Route
          path="/register01"
          element={<Masterauthwebpage name="Register01" />}
        />
        <Route
          path="/register02"
          element={<Masterauthwebpage name="Register02" />}
        />
        <Route
          path="/register02/:otp"
          element={<Masterauthwebpage name="Register02b" />}
        />
        <Route
          path="/forgotpassword01"
          element={<Masterauthwebpage name="Forgotpassword01" />}
        />
        <Route
          path="/forgotpassword02"
          element={<Masterauthwebpage name="Forgotpassword02" />}
        />
        <Route
          path="/forgotpassword02/:otp"
          element={<Masterauthwebpage name="Forgotpassword02b" />}
        />
        {/* End of Admin Login Section */}

        <Route path="/verifyreceipt" element={<VerifyPurchase />} />

        {/* Admin Authoried Access */}

          {/* Added Components */}

          <Route path="/gps" element={<Masterwebpage name="GPS" />} />
          <Route path="/crop" element={<Masterwebpage name="Crop" />} />
          <Route path="/pdf" element={<Masterwebpage name="Pdf" />} />
          <Route path="/qrcodes" element={<Masterwebpage name="QrCodes" />} />
          <Route path="/qrscanner" element={<Masterwebpage name="QrScanner" />} />
          <Route path="/camera" element={<Masterwebpage name="Camera" />} />

          {/* End off Added Components */}

          <Route
            path="/admin/post"
            element={<Masterwebpage name="Post" />}
          />
          <Route
            path="/admin/allpost"
            element={<Masterwebpage name="AllPost" />}
          />
          <Route
            path="/admin/dashboard"
            element={<Masterwebpage name="Dashboard" />}
          />
          <Route
            path="/admin/students"
            element={<Masterwebpage name="Students" />}
          />
          <Route
            path="/admin/payments"
            element={<Masterwebpage name="Payments" />}
          />
          <Route
            path="/admin/quotemessages"
            element={<Masterwebpage name="Quotemessages" />}
          />
          <Route
            path="/admin/quotemessages/:trackId"
            element={<Masterwebpage name="Quotemessages" />}
          />
          <Route
            path="/admin/purchaseditems"
            element={<Masterwebpage name="Purchaseditems" />}
          />
        
        {/* End of Admin Authoried Access */}
        <Route path="/*" element={<Masterwebpage name="Page404" />} />
        
      </Routes>
  );
}

export default App;
