import React from 'react';
import Turnstile, { useTurnstile } from "react-turnstile";

/*
  SITEKEYDemo: 0x4AAAAAAAFlI6vF19bznFua
  SECRETDemo: 0x4AAAAAAAFlIyc0avrEUex4mGRvrF_01b0
  
  SITEKEYLive: 0x4AAAAAAAdEm1_usH9lyApg
  SECRETLive: 0x4AAAAAAAdEm6Iy-D2wGQOxYwVSehiNDOo
  */

//const siteKeys = "0x4AAAAAAAFlI6vF19bznFua"; //Demo
const siteKeys = "0x4AAAAAAAdEm1_usH9lyApg"; //Live



const TurnstileWidget = (props) => {
   
    //const turnstile = useTurnstile();

    return (
        <>
            <Turnstile
                sitekey={siteKeys}
                onVerify={(token) => {
                    fetch("/login", {
                        method: "POST",
                        body: JSON.stringify({ token }),
                    }).then((response) => {
                        //if (!response.ok) turnstile.reset();
                        props.setReCaptcha(true);
                    });
                }}
            />
        </>
    )
}

export default TurnstileWidget
