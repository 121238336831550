import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import CAPTCHATurnStile from "../admin/childComponents/appComponents/CAPTCHATurnStile";

const buttonName = 'Login';
const Login = () => {
  const navigate = useNavigate("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberLogin, setRememberLogin] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [loading, setLoading] = useState(buttonName); //to show loading process

  async function submitForm(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    const datas = {
      email: email,
      password: password,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/login", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);

            localStorage.setItem("admin_authority", res.data.admin_authority);
            localStorage.setItem("admin_type", res.data.admin_type);
            localStorage.setItem("admin_status", res.data.admin_status);
            localStorage.setItem("admin_passport", res.data.admin_passport);
            localStorage.setItem("admin_signature", res.data.admin_signature);
            localStorage.setItem("admin_id", res.data.admin_id);
            localStorage.setItem("auth_token", res.data.token);
            localStorage.setItem("auth_name", res.data.admin_name);
            localStorage.setItem("bend", res.data.bend);
            /*
              let resp = axios.get("api/user");
              console.log(resp.data, '$$$444');
            */
            swal("Login Successful", res.data.message, "success");
            setLoading(buttonName);
            navigate("/admin/dashboard"); //redirect to the Dashboard page
          } else if (res.data.status === 401) {
            swal("Oop!", res.data.message, "warning");
            setLoading(buttonName);
          } else if (res.data.status === 500) {
            swal("Oop!", res.data.message, "warning");
            setLoading(buttonName);
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Login Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (<>
    <h2 className="login-title">Log in</h2>

    <div className="form-group">
      <div className="input-group-icon right">
        <div className="input-icon"><i className="fa fa-envelope"></i></div>
        <input
          className="form-control"
          id="email"
          type="email"
          placeholder="name@example.com"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </div>
    </div>
    <div className="form-group">
      <div className="input-group-icon right">
        <div className="input-icon"><i className="fa fa-lock font-16"></i></div>
        <input
          className="form-control"
          id="password"
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          placeholder="Password"
        />
      </div>
    </div>

    <div className="form-group d-flex justify-content-between">
      <label className="ui-checkbox ui-checkbox-info">
        <input
          className="form-check-input"
          id="rememberLogin"
          type="checkbox"
          value={rememberLogin}
          onChange={(event) => {
            setRememberLogin(event.target.value);
          }}
        />
        <span className="input-span"></span>Remember me</label>
      <Link to="/forgotpassword01">Forgot password?</Link>
    </div>

    <div className="form-group">
      <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />
    </div>

    <div className="form-group">
      <button className="btn btn-primary btn-block col-12" type="submit" disabled={!reCaptcha}
        onClick={submitForm}>{loading}</button>
    </div>

    <div className="social-auth-hr">
      <span>Follow Us On</span>
    </div>
    <div className="text-center social-auth m-b-20">
      <Link className="btn btn-social-icon btn-twitter m-r-5" to="#"><i className="fa fa-twitter"></i></Link>
      <Link className="btn btn-social-icon btn-facebook m-r-5" to="#"><i className="fa fa-facebook"></i></Link>
      <Link className="btn btn-social-icon btn-google m-r-5" to="#"><i className="fa fa-google-plus"></i></Link>
      <Link className="btn btn-social-icon btn-linkedin m-r-5" to="#"><i className="fa fa-linkedin"></i></Link>
    </div>
    <div className="text-center">Not a member?
      <Link className="color-blue" to="/register01"> Need an account? Sign up!</Link>
    </div>
  </>
  );

};

export default Login;
