import React from "react";
import ReactECharts from 'echarts-for-react';

const LineGraph = (props) => {
    const options = {
      title: {
        text: props.text,
        subtext: props.subtext
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [props.labelName1,  props.labelName2]
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          // restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      grid: {
        left: '1%',
        right: '4%',
        bottom: '0%',
        top: '20%',
        containLabel: true
      },
      calculable: true,
      
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.data && props.data.map((dbdata) => dbdata.itemType)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: props.data && props.data.map((dbdata) => dbdata.quantity1),
            name: props.labelName1,
            type: 'line',
            areaStyle: {},
            smooth: true
          },
          {
            data: props.data && props.data.map((dbdata) => dbdata.quantity2),
            name: props.labelName2,
            type: 'line',
            areaStyle: {},
            smooth: true
          }
        ],
        //height: '220px',
      };
    return <>
        <ReactECharts option={options} />
    </>
};

export default LineGraph;


