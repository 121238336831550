import React, { useState, useEffect } from 'react'
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import PostUpdate from "./childComponents/display/PostUpdate";
import PublicationDefualt from "./childComponents/display/PublicationDefualt";
import FileUpload from "./childComponents/appComponents/FileUpload";

/* For Image Crop */
import Crop from "./childComponents/appComponents/Crop";
import CropRatio from "./childComponents/appComponents/CropRatio";
/* End of For Image Crop */

import ActiveStatus from "./childComponents/inputComponents/ActiveStatus";
import PageCatigory from "./childComponents/inputComponents/PageCatigory";
import PageLayout from "./childComponents/inputComponents/PageLayout";
import Loading from "./childComponents/appComponents/Loading";
const buttonName = 'Submit';// Submit Button Name

const Post = () => {

    const [loading, setloading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process

    const [pageLayout, setPageLayout] = useState();
    const [pageCatigory, setPageCatigory] = useState();
    const [activeStatus, setActiveStatus] = useState();
    const [editposts, setEditposts] = useState('');

    useEffect(() => {
        const initializeEditposts = { reviews: '', specification: '', message: '', pageTitle: '', position: '', quantity: '', price: '', productCatigory: '', extra01: '', extra02: '', extra03: '', extra04: '' };
        setEditposts(initializeEditposts);
    }, []);

    const [data, setData] = useState('');

    /* For data Upload */
    const [returnUpload, setReturnUpload] = useState('');

    /* For Image Crop */
    const [returnRatio, setReturnRatio] = useState('');
    const [returnCrop, setReturnCrop] = useState('');
    const [cropImage, setCropImage] = useState('');
    const [closeModal, setCloseModal] = useState(false);
    /* End of For Image Crop */

    /* select which componet to display */
    let tagName;

    switch (pageLayout) {
        case "About":
            /* Send the value for edit inputs to grand child, create container to recieve the edithed data inputs to here*/
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Disclaimer":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Feature":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Quote":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Projects":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Service":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Team":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Testimonial":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "ChooseUs":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Contact":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        case "Product":
            tagName = <PostUpdate editpost={editposts} setData={setData} pageLayout={pageLayout} />;
            break;
        default:
            tagName = <PublicationDefualt editpost={editposts} setData={setData} pageLayout={"About"} />;
    }
    /* End of select which componet to display */


    /* End of Loads the select fileds */
    // async function cropPhotoImage(event) {
    //     event.preventDefault();
    //     if ((aspectRatios === 'Please Image Type') || (!returnUpload.myFileUrl)) {

    //         return swal("Caution", "Please select an image to upload, then select the Image Type", "warning");
    //     }

    //     setCropImage(returnUpload.myFileUrl);
    //     setCloseModal(true);
    // }

    async function submitUpload(event) {

        event.preventDefault();

        if (!returnUpload.myFile) {
            console.log("No file Selected");
            return swal("Caution", "Please select a file or image to upload", "warning");

        }
        setBtnLoading(<div>Processsing...</div>);

        let fileUploadItem = returnUpload.myFile;

        if (returnCrop) {
            fileUploadItem = returnCrop;
            //console.log('$$$Next', returnCrop.fulfilled);
        }

        const formData = new FormData();

        formData.append('uploadFile', fileUploadItem);
        formData.append('myFileUpdate', returnUpload.myFileUpdate);

        formData.append('pageTitle', data.pageTitle);
        formData.append('message', data.message);
        formData.append('reviews', data.reviews);
        formData.append('specification', data.specification);
        formData.append('pageLayout', pageLayout);
        formData.append('pageCatigory', pageCatigory);
        formData.append('activeStatus', activeStatus);
        formData.append('position', data.position);

        formData.append('extra01', data.extra01);
        formData.append('extra02', data.extra02);
        formData.append('extra03', data.extra03);
        formData.append('extra04', data.extra04);


        if (pageLayout === 'Product') {
            formData.append('productCatigory', data.category);
            formData.append('price', data.price);
            formData.append('quantity', data.quantity);
        }

        await axios.get(`sanctum/csrf-cookie`).then((response) => {
            try {
                axios.post(`api/admin/v1/post_page`, formData).then((res) => {
                    if (res.data.status === 200) {

                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        swal("Success", res.data.message, "success");
                        setBtnLoading(buttonName);
                        softreload();
                        //navigate('/admin/allpost'); //redirect to the 

                    } else if (res.data.status === 500) {

                        swal("Caution", res.data.message, "warning");
                        //navigate("/register02"); //redirect to the home page
                        setBtnLoading(buttonName);
                    } else {
                        swal("Failed", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                        setBtnLoading(buttonName);
                    }

                });
            } catch (error) {
                swal("Oops!", "Publication Failed", "error");
                console.log(error.response.data);
            }
        });
    }

    useEffect(() => {
        setloading(false);
    }, []);

    if (loading) {
        // Loading, Processing Payment, Please Dont Interrupt
        return <Loading message01={'Loading...'} />;
    }

    return (
        <>
            {closeModal ? (
                <Crop imageCropUrl={cropImage} setReturnCrop={setReturnCrop} returnRatio={returnRatio} setCloseModal={setCloseModal} />) : null
            }

            <div className='container-lg-fluid m-3'>

                <form onSubmit={submitUpload} encType="multipart/form-data" >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className="card">
                                <div className="card-header text-white bg-info p-3"><i className="fa fa-newspaper"></i> ADD NEW POST</div>
                                <div className="card-body">
                                    <div className='row m-1'>

                                        <PageCatigory titles='Page Category' nameId='pageCatigory' placeholder='Please Select Page Category' size='3' inputState='' moreDetail={'editposts'} setPageCatigory={setPageCatigory} />

                                        <PageLayout titles='Page Layout' nameId='pageLayout' placeholder='Please Select Page Layout' size='3' inputState='' moreDetail={'editposts'} setPageLayout={setPageLayout} />

                                        <ActiveStatus titles='Status' nameId='activeStatus' placeholder='Please Select Status' size='3' inputState='' moreDetail={'editposts'} setActiveStatus={setActiveStatus} />

                                        <div className='col-lg-3'>
                                            <div className="row form-group mt-3">
                                                <div className='col-lg-3'>
                                                    <Link className='btn btn-primary col-12 mt-4' onClick={reload} to='/admin/post'>
                                                        <i className='fa fa-refresh'></i>
                                                    </Link>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <button className='btn btn-primary col-12 mt-4'>
                                                        <div className=' d-flex justify-content-center'>
                                                            <i className="fa fa-power-off m-1 col-lg-3"></i>{btnLoading}
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {tagName}


                        {/* send data to be displayed (post is empty), collect processed result for the parent componet*/}
                        <FileUpload editposts={''} setReturnUpload={setReturnUpload} />
                        <CropRatio setCloseModal={setCloseModal} setCropImage={setCropImage} returnUploader={returnUpload.myFileUrl} setReturnRatio={setReturnRatio} />

                    </div>
                </form>
            </div>

        </>
    )

    function softreload() {
        setPageLayout('');
        setPageCatigory('');
        setActiveStatus('');
    }
    function reload() {
        window.location.reload();
    }
}

export default Post
