import React, { useEffect, useState } from 'react'
const TextMessage = (props) => {

    const [textMessage, setTextMessage] = useState('');

    const [nameId, setNameId] = useState('');// The title name
    const [size, setSize] = useState(6); // The size of the field
    const [inputState, setInputState] = useState(); // The size of the field
    const [placeholder, setPlaceholder] = useState(''); // The size of the field
    const [titles, setTitles] = useState(''); // The title of the field
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setTextMessage(props.moreDetail.textMessage); // The value

        setNameId(props.nameId);
        setSize(props.size);
        setInputState(props.inputState);
        setPlaceholder(props.placeholder);
        setTitles(props.titles);
    }, [props.moreDetail.textMessage, props.nameId, props.inputState, props.size, props.placeholder, props.titles]);
    /* End of Load all File with data */
    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(textMessage);
    }, [textMessage]);

    props.setTextMessage(updateData);
    /* End of Send all Files data to parents */
    return (
        <>
            <div className={"col-md-"+size}>
            <div className="form-floating">
                <textarea className="form-control" 
                
                name={nameId}
                        disabled={inputState}
                        id={nameId}
                        placeholder={placeholder}
                        
                value={textMessage} 
                 style={{ height: "180px" }} onChange={(event) => {
                    setTextMessage(event.target.value);
                }}></textarea>
                <label for="subject">{titles}</label>
            </div>
            </div>
        </>
    )
}

export default TextMessage
