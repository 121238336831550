import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import CAPTCHATurnStile from "../admin/childComponents/appComponents/CAPTCHATurnStile";

const buttonName = 'Reset Password';
const Forgotpassword02b = () => {
  const { otp } = useParams();
  const navigate = useNavigate("");

  const [otpToken, setOtptoken] = useState({ otp }.otp);
  const [email, setEmail] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [password, setPassword] = useState();
  const [repeatpassword, setRepeatpassword] = useState();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [agree, setAgree] = useState('on');
  const [loading, setLoading] = useState(buttonName); //to show loading process


  /* load the user email and phone number automatically */
  useEffect((otpToken) => {
    (async () => await tableLoad(otpToken))();
  });

  async function tableLoad(otp) {
    await axios.get("api/admin/v1/verifyotp/" + otpToken).then((res) => {
      // Gets the address of the backend server to be able to point the Images currectly
      if (res.data.status === 200) {
        //localStorage.setItem("bend", res.data.bend);

        setEmail(res.data.email);
        setLastname(res.data.lastName);
        setFirstname(res.data.firstName);
      } else {
        console.log(res.data.validation_error);
      }
    });

  }
  /* End of load the user email and phone number automatically */

  async function resetPassword(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    const datas = {
      otpToken: otpToken,
      //firstName: firstName,
      //lastName: lastName,
      email: email,
      password: password,
      repeatpassword: repeatpassword,
      //agree: agree,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/forgot_password/create", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);
            /*
              localStorage.setItem('auth_token', res.data.token);
              localStorage.setItem('auth_name', res.data.firstName);
              */
            swal("Success", res.data.message, "success");
            setLoading(buttonName);
            navigate("/login"); //redirect to the home page
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Registration Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (<>
    <h2 className="login-title">Forgot Password</h2>
    <p class="m-b-20">Enter your email new password and we will reset it for you.</p>
    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-phone"></i></div>
            <input
              className="form-control"
              id="otptoken"
              type="number"
              value={otpToken}
              onChange={(event) => {
                setOtptoken(event.target.value);
              }}
              placeholder="Paste the OTP"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-envelope"></i></div>
            <input
              disabled
              className="form-control"
              required
              id="email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="name@example.com"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-user"></i></div>
            <input
              disabled
              className="form-control"
              id="firstName"
              type="text"
              value={firstName}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              placeholder="Your first name"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-user"></i></div>
            <input
              disabled
              className="form-control"
              id="lastName"
              type="text"
              value={lastName}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              placeholder="Your last name"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-lock"></i></div>
            <input
              className="form-control"
              required
              id="password"
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              placeholder="Create a password"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div className="form-group">
          <div className="input-group-icon right">
            <div className="input-icon"><i className="fa fa-lock"></i></div>
            <input
              className="form-control"
              required
              id="repeatpassword"
              value={repeatpassword}
              onChange={(event) => {
                setRepeatpassword(event.target.value);
              }}
              type="password"
              placeholder="Confirm password"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="form-group px-3">

      <input
        className="form-check-input"
        id="agree"
        type="checkbox"
        name="agree"
        value={agree}
        onChange={(event) => {
          setAgree(event.target.value);
        }}
      />
      <label className="form-check-label" for="agree">
        I agree the terms and policy
      </label>

    </div>
    <div className="form-group">
      <CAPTCHATurnStile reCaptcha={reCaptcha} setReCaptcha={setReCaptcha} />
    </div>
    <div className="form-group">
      <button
        disabled={!reCaptcha}
        className="btn btn-primary col-12"
        onClick={resetPassword}
      >
        {loading}
      </button>
    </div>


    <div className="social-auth-hr">
      <span>Follow Us On</span>
    </div>
    <div className="text-center social-auth m-b-20">
      <Link className="btn btn-social-icon btn-twitter m-r-5" to="#"><i className="fa fa-twitter"></i></Link>
      <Link className="btn btn-social-icon btn-facebook m-r-5" to="#"><i className="fa fa-facebook"></i></Link>
      <Link className="btn btn-social-icon btn-google m-r-5" to="#"><i className="fa fa-google-plus"></i></Link>
      <Link className="btn btn-social-icon btn-linkedin m-r-5" to="#"><i className="fa fa-linkedin"></i></Link>
    </div>


    <div className="card-footer text-center py-3 d-flex justify-content-between">
      <Link className="small" to="/login">
        Return to login
      </Link>
      <div className="small">
        <Link to="/register01">Need an account? Sign up!</Link>
      </div>
    </div>
  </>
  );
};
export default Forgotpassword02b;
