import React, { useState, useEffect } from 'react';
import axios from "axios";
import swal from "sweetalert";
import { BsBank } from "react-icons/bs";

import Subject from "../../childComponents/RequestQuoteContact/Subject";
import Amount from "../../childComponents/RequestQuoteContact/Amount";
import ActionType from "../../childComponents/RequestQuoteContact/ActionType";
import { MdSubject } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
const buttonName = 'Save Changes';// Submit Button Name
const ModalWithDraw = (props) => {
    const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
    const [amount, setAmount] = useState('');
    const [subject, setSubject] = useState('');
    const [title, setTitle] = useState('');
    const [actionType, setActionType] = useState('');
    

    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);
    function numberWithCommas(x) {
        if (!x) { x = 0; }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

    /* select which componet to display */

    let tagName;
    //Feature, About, Projects, Quote, Service, Team, Testimonial, ChooseUs, Contact, Product
    switch (title) {
        case "CREDIT/ WITHDRAW FUNDS":
            tagName = <>

                <div className='col-6 py-3'>
                <h6><MdSubject style={{fontSize:"20px"}}/> To:</h6>
                    <Subject titles='Subject' nameId='subject' placeholder='Reason for the debit or credit action' size='12' inputState='' moreDetail={'props.editpost'} setSubject={setSubject} />
                </div>

                <div className='col-6 py-3'>
                <h6><GiTakeMyMoney style={{fontSize:"20px"}}/> To:</h6>
                    <ActionType titles='ActionType' nameId='actionType' placeholder='Type of transaction credited or debited' size='12' inputState='' moreDetail={'props.editpost'} setActionType={setActionType} />
                </div>
                
                <div className='col-12 py-3'>
                <h6><FaMoneyBillWave style={{fontSize:"20px"}}/> To:</h6>
                    <Amount titles='Amount' nameId='amount' placeholder='Amount to be credited or debited' size='12' inputState='' moreDetail={'props.editpost'} setAmount={setAmount} />

                    <h5 className='mt-3'>Account Ballance: <span className='ms-3 text-danger fw-bolder' >₦{numberWithCommas(Number(localStorage.getItem("allGrandTotal")))}</span></h5>
                </div>
            </>;
            break;

        default:
            tagName = <>

            </>;
            break;
    }

    /* End of select which componet to display */

    async function submitUpload(event) {

        event.preventDefault();

        setBtnLoading(<div>Processsing...</div>);

        
        let formData;
    
        formData = new FormData();
        formData.append('subject', subject);
        formData.append('actionType', actionType);
        formData.append('amount', amount);
        formData.append('admin_id', localStorage.getItem('admin_id'));

        await axios.get(`sanctum/csrf-cookie`).then((response) => {
            try {
                axios.post(`api/admin/v1/withdraw`, formData).then((res) => { //This works with file upload
                    //axios.put("api/admin/v1/post_page/" + id, datas).then((res) => { This works without file upload
                    if (res.data.status === 200) {

                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        swal("Success", res.data.message, "success");
                        setBtnLoading(buttonName);
                        props.formCleanners(); // Reset the displayed table file
                        props.setCloseModal(true);

                        //navigate('/admin/allpost'); //redirect to the 

                    } else if (res.data.status === 500) {

                        swal("Caution", res.data.message, "warning");
                        //navigate("/register02"); //redirect to the home page
                        setBtnLoading(buttonName);
                    } else {
                        swal("Failed", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                        setBtnLoading(buttonName);
                    }

                });
            } catch (error) {
                swal("Oops!", "Failed", "error");
                console.log(error.response.data);
            }
        });
    }

    return (
        <>


            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className="card">
                        <div className="card-header text-white bg-info p-3"><BsBank style={{ fontSize: '20px' }} /> CREDIT/ WITHDRAW </div>
                        <div className="card-body">
                            <div className='row m-1'>

                                <div className='row col-9'>

                                    {tagName}
                                </div>

                                <div className='col-lg-3'>
                                    <div className="form-group mt-3">
                                        <button className='btn btn-primary col-9 ms-3 mt-4'><div className=' d-flex flex-row' onClick={submitUpload}> <i className="fa fa-power-off m-1 col-3"></i>{btnLoading}</div></button>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>)

}
export default ModalWithDraw
