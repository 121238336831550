import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FaAudioDescription } from "react-icons/fa";
const Reviews = (props) => {
const [reviews, setReviews] = useState('');

    const [nameId, setNameId] = useState('');// The title name
    const [titles, setTitles] = useState(''); // The title of the field
    const [size, setSize] = useState(6); // The size of the field

    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 
    /* Load all File with data */
    useEffect(() => {
        setReviews(props.moreDetail.reviews); // The value

        // setInputState(props.inputState);
        // setPlaceholder(props.placeholder);
        setNameId(props.nameId);
        setSize(props.size);
        setTitles(props.titles);
    }, [props.moreDetail.reviews, props.nameId, props.size, props.titles]);
    /* End of Load all File with data */

    /* Send all Files data to parents */
    useEffect(() => {
        setUpdateData(reviews);
    }, [reviews]);

    props.setReviews(updateData);
    /* End of Send all Files data to parents */
    return (
        <>

            <div className={'col-lg-' + size}>
                <div className="form-group mt-3">
                    <label for={nameId} className="col-sm-12">
                        <FaAudioDescription style={{ fontSize: '20px' }} />    {titles}
                        {/* Publication/ Description */}
                    </label>
                    <div className="col-sm-12">
                        <CKEditor
                            editor={ClassicEditor}
                            data={reviews}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                //console.log( event );
                                setReviews(editor.getData());

                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />

                    </div>
                </div>
            </div>

        </>
    )

}

export default Reviews
