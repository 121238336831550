import { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import QrCodes from './QrCodes';
//import GPS from './GPS';
import HtmlDo from './HtmlDo';
import ModalPurchItem from '../popups/ModalPurchItem';
import ModalPurchItems from '../popups/ModalPurchItems';
import ModalTransSheet from '../popups/ModalTransSheet';


function Pdf(props) {

	const [referenceNo, setReferenceNo] = useState('');
	const [itemIds, setItemIds] = useState('');
	const [docTypes, setDocTypes] = useState('');
	const QrCodeRef = useRef(null);
	useEffect(() => {

		setReferenceNo(props.referenceNo);
		setItemIds(props.id);
		setDocTypes(props.docType);
	}, [props.referenceNo, props.id, props.docType]);

	/* Create Pdf document */
	const handleGeneratePdf = () => {
		const doc = new jsPDF('vertical', 'px', 'a4', 'false');//vertical, landscape, portrait

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'normal');

		doc.html(QrCodeRef.current, {
			async callback(doc) {
				await doc.save('document');
			},
			x: 20,
			y: 20,
			width: 800, // <- here
			windowWidth: 800, // <- here
			html2canvas: { scale: 0.5 } // change the scale to whatever number you need. Normal : 1.0
		});
	};
	/* End of Create Pdf document */


	let tagName;
	switch (docTypes) {
		case "HtmlDo":
			tagName = <HtmlDo />;
			break;
		case "ModalPurchItem":
			tagName = <ModalPurchItem itemId={itemIds} direction={'2'} />;
			break;
		case "ModalPurchItems":
			tagName = <ModalPurchItems itemId={itemIds} direction={'2'} />;
			break;
		case "ModalTransSheet":
			tagName = <ModalTransSheet itemId={itemIds} direction={'2'} />;
			break;
		default:
			tagName = <ModalPurchItems itemId={itemIds} direction={'2'} />;
	}

	return (
		<div>

			{/* Create Pdf document */}

			<div>
				<button className="btn btn-primary" onClick={handleGeneratePdf}>
					Generate PDF
				</button>
			</div>
			<div ref={QrCodeRef}>

				{/* Request QRCODE */}
				{referenceNo && 
				<div style={{ marginLeft: '40%' }}>
					<QrCodes addrurl={referenceNo} qrcodeSize={'30%'} />
				</div>}
				{/* End of Request QRCODE */}

				{/* Request Html Document */}
				<div>{tagName}</div>
				{/* End of Request Html Document */}
			</div>
			{/* End of Create Pdf document */}
		</div>
	);
}

export default Pdf;