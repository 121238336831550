import React from "react";
import ReactECharts from 'echarts-for-react';

const PieChart = (props) => {
    const options = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '0%',
            left: 'center',
           //data: ['Items Sold']
        },
        series: [
            {
                name: props.labelName,
                type: 'pie',
                top: '15%',
                radius: ['40%', '90%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 4
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: true
                },
                data: 
                    props.data && props.data.map((dbdata) => {
                        let quantity = dbdata.quantity;
                        let itemType = dbdata.itemType;
                        return { value: quantity, name: itemType }
                                
                    }),
                
            }
        ]
    };
    return <>
        <ReactECharts option={options} />
    </>
};

export default PieChart;


